<template>
	<div v-if="data">
		<div class="margin-top-20" v-if="data && data.cost && data.cost.now && data.cost.now.content1" v-html="data.cost.now.content1">
			{{data.cost.now.content1 ? data.cost.now.content1 : ''}}
		</div>
		<div class="text-center margin-top-20">
			<el-row>
				<el-col :span="24">
					<lee-bar-echarts-hor
						key="bar2"
						ref="bar2"
						:grid-bottom="10"
						:grid-top="30"
						grid-right="10%"
						height="350px"
						:show-legend="false"
						legend-orient="vertical"
						legend-pos="right"
						:show-left="true"
						:show-right="false"
						:mark-line="markLine"
					></lee-bar-echarts-hor>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
export default {
	name: "IndexHyData5",
	data() {
		return {
			color:['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom: {
				year: '',
				times: '',
				time_type: '',
			},
			searchData: '',
			searchQuarterData: '',
			pieData: null,
			pie2Data: null,
			markLine:[]
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataChanliangCost/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataChanliangCost/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		getTotal1(year) {
			let _this = this;
			let _where = {};
			if(this.searchData){
				_where.year = [this.searchData,'<=']
			}
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
			}else{
				_where.time_type = 'year';
			}
			this.$http.post({
				url: '/HydataChanliangCost/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata2 = {bar1: [], xaxis: []};

					_bardata2.bar1.push(res.cost.now.shucai);
					_bardata2.bar1.push(res.cost.now.rou);
					_bardata2.bar1.push(res.cost.now.qin);
					_bardata2.bar1.push(res.cost.now.shuichanpin);
					_bardata2.bar1.push(res.cost.now.dan);
					_bardata2.bar1.push(res.cost.now.nai);
					_bardata2.bar1.push(res.cost.now.shuiguo);
					_bardata2.xaxis = ['蔬菜及食用菌','肉类','禽类','水产品','蛋类','奶类','干鲜瓜果类']

					let _line = {label:{formatter:'{b}:{c}千克'},data: []};
					if(res.cost.now.fada_shucai > 0){
						let _lineData = { name: '发达国家蔬菜人均消费量',xAxis: res.cost.now.fada_shucai,lineStyle:{color:_this.color[0], width:2} }
						_line.data.push(_lineData)
					}
					if(res.cost.now.fada_rou > 0){
						let _lineData = { name: '发达国家肉类人均消费量',xAxis: res.cost.now.fada_rou,lineStyle:{color:_this.color[1], width:2} }
						_line.data.push(_lineData)
					}
					if(res.cost.now.fada_qin > 0){
						let _lineData = { name: '发达国家禽类人均消费量',xAxis: res.cost.now.fada_qin,lineStyle:{color:_this.color[2], width:2} }
						_line.data.push(_lineData)
					}
					if(res.cost.now.fada_shuichanpin > 0){
						let _lineData = { name: '发达国家水产品人均消费量',xAxis: res.cost.now.fada_shuichanpin,lineStyle:{color:_this.color[3], width:2} }
						_line.data.push(_lineData)
					}
					if(res.cost.now.fada_dan > 0){
						let _lineData = { name: '发达国家蛋类人均消费量',xAxis: res.cost.now.fada_dan,lineStyle:{color:_this.color[4], width:2} }
						_line.data.push(_lineData)
					}
					if(res.cost.now.fada_nai > 0){
						let _lineData = { name: '发达国家奶类人均消费量',xAxis: res.cost.now.fada_nai,lineStyle:{color:_this.color[5], width:2} }
						_line.data.push(_lineData)
					}
					if(res.cost.now.fada_shuiguo > 0){
						let _lineData = { name: '发达国家瓜果类人均消费量',xAxis: res.cost.now.fada_shuiguo,lineStyle:{color:_this.color[6], width:2} }
						_line.data.push(_lineData)
					}
					_this.markLine.push(_line)

					setTimeout(()=>{

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '人均消费量', type: 'bar', color: 'most', data: _bardata2.bar1, dw: '千克'},
								],
								_bardata2.xaxis
							);
						}
					},200)
				}
			});
		},
	}
}
</script>
