<template>
	<lee-page code="hydata" bg-img="/images/banner/3.jpg" title="行业数据" titleClass="margin-top-200 light f70" sub-title="Industry Data" height="528" des="" :bread="[{title:'行业数据'}]">
		<div class="bg-img-5">
			<div class="wrap1100">
				<div class="cl"></div>
				<div class="margin-top-100 padding-bottom-100">
					<el-row :gutter="40">
						<el-col :span="8">
							<div class="hide hand" style="border-radius:30px;width:100%;height:180px;background-image: url('/images/hydata/1.png');background-repeat: no-repeat;background-size: 100% 100%" @click="$router.push({path:'/hydata/coldchain'})">
								<div style="color: #0a8050;margin:50px 0 0 30px;" class="f30 f-lq">宏观冷链</div>
								<div style="color: #6ac9a2;margin:20px 0 0 30px;" class="f16">MACRO-COLD CHAIN</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="hide hand" style="border-radius:30px;width:100%;height:180px;background-image: url('/images/hydata/2.png');background-repeat: no-repeat;background-size: 100% 100%" @click="$router.push({path:'/hydata/coldstorage'})">
								<div style="color: #14295c;margin:50px 0 0 30px;" class="f30 f-lq">冷库</div>
								<div style="color: #758ecc;margin:20px 0 0 30px;" class="f16">COLD STORAGE</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="hide hand" style="border-radius:30px;width:100%;height:180px;background-image: url('/images/hydata/3.png');background-repeat: no-repeat;background-size: 100% 100%" @click="$router.push({path:'/hydata/shebei'})">
								<div style="color: #925f29;margin:50px 0 0 30px;" class="f30 f-lq">冷链设施设备</div>
								<div style="color: #d5ac81;margin:20px 0 0 30px;" class="f16">COLD CHAIN FACILITIES</div>
							</div>
						</el-col>
					</el-row>
					<div class="margin-top-50">
						<el-row :gutter="40">
							<el-col :span="8">
								<div class="hide hand" style="border-radius:30px;width:100%;height:180px;background-image: url('/images/hydata/4.png');background-repeat: no-repeat;background-size: 100% 100%" @click="$router.push({path:'/hydata/coldcar'})">
									<div style="color: #2c3174;margin:50px 0 0 30px;" class="f30 f-lq">冷藏车</div>
									<div style="color: #7e83c5;margin:20px 0 0 30px;" class="f16">COLD CAR</div>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="hide hand" style="border-radius:30px;width:100%;height:180px;background-image: url('/images/hydata/5.png');background-repeat: no-repeat;background-size: 100% 100%" @click="$router.push({path:'/hydata/harvest'})">
									<div style="color: #0a8050;margin:50px 0 0 30px;" class="f30 f-lq">产销量</div>
									<div style="color: #6ac9a2;margin:20px 0 0 30px;" class="f16">PRODUCTION</div>
								</div>
							</el-col>
							<el-col :span="8">
								<div class="hide hand" style="border-radius:30px;width:100%;height:180px;background-image: url('/images/hydata/6.png');background-repeat: no-repeat;background-size: 100% 100%" @click="$router.push({path:'/hydata/inout'})">
									<div style="color: #6d1a1a;margin:50px 0 0 30px;" class="f30 f-lq">进出口</div>
									<div style="color: #cb7c7c;margin:20px 0 0 30px;" class="f16">IMPORT AND EXPORT</div>
								</div>
							</el-col>
						</el-row>
					</div>
<!--					<div class="flex flex-ai flex-jc margin-top-50">-->
<!--						<div class="hide" style="margin-right:40px;display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">-->
<!--							<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #78b0ff;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/coldcar'})">-->
<!--								<img src="/images/icon/icon_9_on.png" style="height: 70px;"/>-->
<!--								<div class="f-lq light f28 margin-top-20">冷藏车</div>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="hide" style="margin-right:40px;display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">-->
<!--							<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #90c900;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/harvest'})">-->
<!--								<img src="/images/icon/icon_14_on.png" style="height: 70px;"/>-->
<!--								<div class="f-lq light f28 margin-top-20">产销量</div>-->
<!--							</div>-->
<!--						</div>-->
<!--						<div class="hide" style="display:flex;align-items:center;justify-content: center ;height: 260px;width: 260px;border-radius:999px;background-color: #f2f2f2;">-->
<!--							<div class="hand hide flex flex-column flex-jc flex-ai" style="background-color: #e1b547;height: 200px;width: 200px;border-radius:999px;" @click="$router.push({path:'/hydata/inout'})">-->
<!--								<img src="/images/icon/icon_13_on.png" style="height: 70px;"/>-->
<!--								<div class="f-lq light f28 margin-top-20">进出口</div>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			hyList: [
				{title: '冷藏车', icon: '/images/icon/icon_9_on.png', path: '/hydata/coldcar'},
				{title: '冷库', icon: '/images/icon/icon_10_on.png', path: '/hydata/coldstorage'},
				{title: '冷链物流', icon: '/images/icon/icon_11_on.png', path: '/hydata/coldchain'},
				{title: '产量', icon: '/images/icon/icon_12_on.png', path: '/hydata/harvest'},
				{title: '进出口', icon: '/images/icon/icon_13_on.png', path: '/hydata/inout'},
				{title: '农批', icon: '/images/icon/icon_14_on.png', path: '/hydata/agriculture'},
				{title: '特殊农品', icon: '/images/icon/icon_15_on.png', path: '/hydata/characteristic'},
			]
		}
	},
	methods: {
		prevSwiper() {
			this.$refs['swiper'].prev();
		},
		nextSwiper() {
			this.$refs['swiper'].next();
		}
	}
}
</script>
