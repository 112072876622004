<template>
	<lee-page abs-bg-img="/images/bg_head_10.jpg" title-class="margin-top-100" :height="280" abs-title="注册">
		<div class="bg-img-2 padding-bottom-100 padding-top-100">
			<div class="cl"></div>
			<div class="margin-auto bg-light border-radius-5 padding-20" style="width:700px;">
				<div class="green cl border-bottom padding-bottom">
					注册
					<el-button size="mini" type="success" round plain class="y vm" @click="$router.push({path:'/login'})">
						<i class="el-icon-back margin-right"></i>返回登录
					</el-button>
				</div>
				<el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top" :show-message="true">
					<el-form-item label="" prop="username">
						<el-input v-model="formData.username" placeholder="用户名" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="11" show-word-limit></el-input>
						<!--                            <template slot="error" slot-scope="error">-->
						<!--                                <span class="lee-input-msg red">{{error.error}}</span>-->
						<!--&lt;!&ndash;                                <span class="lee-input-msg" v-else>* 用于登录及找回密码</span>&ndash;&gt;-->
						<!--                            </template>-->
						<!--                            <div>{{$slots}}</div>-->
						<span class="lee-input-msg">* 用于登录及找回密码</span>
					</el-form-item>
					<el-form-item label="" prop="password">
						<el-input type="password" v-model="formData.password" placeholder="密码" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="16" show-word-limit></el-input>
					</el-form-item>
					<el-form-item label="" prop="rePassword">
						<el-input type="password" v-model="formData.rePassword" placeholder="确认密码" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="16" show-word-limit></el-input>
					</el-form-item>
					<!--                        <el-form-item label="" prop="country_code" class="margin-bottom">-->
					<!--                            <el-select v-model="formData.country" @change="change" class="mobile fn lee-select bg-gray-4 border-radius-5 vm" value-key="country_code" style="width: 400px;">-->
					<!--                                <el-option-->
					<!--                                    v-for="item in countryList"-->
					<!--                                    :key="item.country_code"-->
					<!--                                    :label="item.country_name"-->
					<!--                                    :value="item">-->
					<!--                                </el-option>-->
					<!--                            </el-select>-->
					<!--                            <span class="lee-input-msg">* 国际地区编码 86</span>-->
					<!--                        </el-form-item>-->
					<!--                        <el-form-item label="" prop="country_code" class="margin-bottom">-->
					<!--                            <lee-select v-model="formData.country_code" style="width: 400px;" :options="countryList" placeholder="选择国家" class="mobile fn"></lee-select>-->
					<!--                            <span class="lee-input-msg">* 国际地区编码 86</span>-->
					<!--                        </el-form-item>-->
					<el-form-item label="" prop="mobile">
						<el-input v-model="formData.mobile" placeholder="手机号码" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="11" show-word-limit></el-input>
						<span class="lee-input-msg">* 用于登录及找回密码</span>
					</el-form-item>
					<el-form-item label="" prop="code">
						<el-input v-model="formData.code" placeholder="验证码" autocomplete="off" class="lee-input mobile margin-right" style="width: 250px;" maxlength="6" show-word-limit></el-input>
						<el-button @click.prevent="sendCode" :disabled="disSms" type="success" class="text-center box-shadow-green" style="width: 140px;">
							{{ codeText }}
						</el-button>
					</el-form-item>
					<el-form-item label="" prop="name">
						<el-input v-model="formData.name" placeholder="姓名" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="4" show-word-limit></el-input>
						<span class="lee-input-msg">* 至少2个字符</span>
					</el-form-item>
					<el-form-item label="" prop="nickname">
						<el-input v-model="formData.nickname" placeholder="昵称" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="10" show-word-limit></el-input>
						<span class="lee-input-msg">* 至少2-10个字符</span>
					</el-form-item>
					<el-form-item label="" prop="company">
						<el-input v-model="formData.company" placeholder="公司名称" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="20" show-word-limit></el-input>
						<span class="lee-input-msg">* 至少2-20个字符</span>
					</el-form-item>
					<el-form-item label="" prop="job">
						<el-input v-model="formData.job" placeholder="职务" autocomplete="off" class="lee-input mobile" style="width: 400px;" maxlength="10" show-word-limit></el-input>
						<span class="lee-input-msg">* 至少2-10个字符</span>
					</el-form-item>
					<div class="margin-top-30 text-center lee-checkbox bg-transparent">
						<el-checkbox v-model="isRead">我已阅读并同意</el-checkbox>
						<span class="green f14 hand" @click="showUserWord = true">《平台用户协议》</span>
					</div>
					<div class="text-center margin-top-30">
						<el-button type="success" class="box-shadow-green" style="width: 200px;" @click="toReg('form')">注册成为会员
						</el-button>
					</div>
				</el-form>
			</div>
		</div>
		<el-dialog title="用户协议" :visible.sync="showUserWord">
			<div class="f16" style="max-height: 600px;overflow-y: auto;" v-html="setting.service"></div>
		</el-dialog>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		let checkRePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.formData.password) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			showUserWord: false,
			formData: {
				username: '',
				password: '',
				rePassword: '',
				code: '',
				country: {country_code: '86', country_name: '中国'},
				nickname: '',
				name: '',
				job: '',
				company: ''
			},
			codeText: '发送验证码',
			disSms: false,
			time: 60,
			timeId: null,
			isRead: false,
			domainList: [],
			countryList: [
				{
					country_code: '86',
					country_name: '中国'
				},
				{
					country_code: '2',
					country_name: '德国'
				},
			],
			rules: {
				username: [
					{required: true, message: '请输入用户名'},
					{min: 4, max: 15, message: '用户名必须是4-15位字母、数字'},
					{pattern: this.$regexp.userName, message: '用户名必须是4-15位字母、数字'}
				],
				password: [
					{required: true, message: '请输入密码'},
					{min: 6, max: 20, message: '密码必须是6-20位字符,包含数字、字母及特殊字符(!#$%^&*.~,)'},
					{pattern: this.$regexp.password, message: '密码只能包含数字、字母及特殊字符(!#$%^&*.~,)'}
				],
				rePassword: [
					{required: true, message: '请确认密码'},
					{min: 6, max: 20, message: '密码必须是6-20位字符,包含数字、字母及特殊字符(!#$%^&*.~,)'},
					{pattern: this.$regexp.password, message: '密码只能包含数字、字母及特殊字符(!#$%^&*.~,)'},
					{validator: checkRePass, message: '两次输入密码不一致!'}
				],
				nickname: [
					{required: true, message: '请输入昵称'},
					{min: 2, max: 10, message: '昵称必须是2-10位数字、字母、汉字'},
					{pattern: this.$regexp.nickName, message: '只能包含数字、字母、汉字'}
				],
				code: [
					{required: true, message: '请输入验证码'}
				],
				mobile: [
					{required: true, message: '请输入手机号'},
					{pattern: this.$regexp.mobile, message: '手机号码格式不正确'},
				],
				name: [
					{required: true, message: '请输入姓名'},
					{min: 2, max: 4, message: '必须是2-4位汉字'},
					{pattern: this.$regexp.name, message: '只能包含汉字'}
				],
				company: [
					{required: true, message: '请输入企业名称'},
					{min: 2, max: 20, message: '必须是2-20位数字、字母、汉字'},
					{pattern: this.$regexp.nickName, message: '只能包含数字、字母、汉字'}
				],
				job: [
					{required: true, message: '请输入职务'},
					{min: 2, max: 10, message: '必须是2-10位数字、字母、汉字'},
					{pattern: this.$regexp.nickName, message: '只能包含数字、字母、汉字'}
				],
			},
			setting: {
				service: ''
			}
		}
	},
	created() {

	},
	mounted() {
		// this.getDomainType();
		this.getService();
	},
	methods: {
		change(e) {
			console.log(e);
		},
		getDomainType() {
			let _this = this;
			this.$http.post({
				url: 'user/domain/type',
				data: null,
				success(res) {
					console.log(res);
					if (res.data) {
						_this.domainList = res.data;
					}
				}
			})
		},
		toReg(formName) {
			if (this.isRead) {
				let _this = this;
				this.$refs[formName].validate(valid => {
					if (valid) {
						_this.$http.post({
							url: '/Common/register',
							data: this.formData,
							success(res) {
								if (res.uid) {
									_this.$message.success('注册成功，请登录');
									_this.$router.push({path: '/login'})
								}
							}
						})
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			} else {
				this.$message.error('请勾选平台用户协议');
			}
		},
		sendCode() {
			let _this = this;
			this.$http.post({
				url: '/Common/sendSms',
				data: this.formData,
				success: (res) => {
					if (res === 'ok') {
						_this.disSms = true;
						_this.startTime();
						_this.$message.success('验证码发送成功');
					}
				}
			});
		},
		getService() {
			let _this = this;

			let _skeys = [];

			for (let k in this.setting) {
				_skeys.push(k);
			}

			this.$http.post({
				url: '/SystemSetting/getSystem',
				data: {skey: _skeys},
				success: (res) => {
					_this.setting.service = res.service;
				}
			});
		},
		timeDown() {
			if (this.time > 0) {
				this.time -= 1;
				this.codeText = this.time + 's 重新获取'
			} else {
				this.disSms = false;
				clearInterval(this.timeId);
				this.timeId = null;
				this.codeText = '发送验证码'
			}
		},
		startTime() {
			this.time = 60;
			this.timeId = setInterval(this.timeDown, 1000);
		},
	},
	destroyed() {
		if (this.timeId) {
			clearInterval(this.timeId);
			this.timeId = null
		}
	}
}
</script>
