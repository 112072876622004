<template>
	<lee-page code="news" bg-img="/images/bg_head_5.jpg" title="资讯" sub-title="Information" height="280" des="" :search="true" :back="false" :bread="[{title:'资讯',url:'/news'},{title:'搜索'}]">
		<div class="bg-img-2  padding-top-50" style="background-color: #f8f9fa;">
			<div class="wrap1100 padding-bottom-100">
				<template v-if="list.length > 0">
					<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ total }}</b> 条相关内容</div>
					<div class="box-shadow bg-light margin-bottom-20 hand rel" style="padding:10px;" v-for="(item,index) in list" @click="$router.push({path:'/news/detail/'+item.id})">
						<div v-if="item.sort > 0" style="background-color: #F56C6C;color:#fff;top:0;left:10px;padding:0 10px;" class="abs f14">置顶</div>
						<img class="z" :src="$utils.formatImagePath(item.head_img)" style="width: 120px;height: 120px;"/>
						<div style="padding-left:130px;height: 120px;">
							<div class="hide">
								<div class="green-line">
									<img src="/images/icon/icon_30.png" height="22" class="z"/>
									<div v-html="item.title" class="f-b" style="padding-left:30px;line-height: 22px;"></div>
								</div>
								<div class="f14 dark-3 text-line-2" v-html="item.content"></div>
								<div class="f14 margin-top" style="color: #ccc;">{{item.create_time}}</div>
							</div>
						</div>
					</div>
					<div class="text-center margin-top-100">
						<el-pagination
							background
							layout="pager, jumper"
							:page-size="pageSize"
							:current-page.sync="searchForm.page"
							:total="total"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</template>
				<div class="text-center dark-3" v-else>
					未查询到相关内容
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			list: [],
			pageSize: 10,
			total: 0,
			searchForm: {
				page: 1,
				title: '',
				title_type: '',
				draft:0
			}
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query,draft:0};
		}

		this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
		this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
		this.searchForm.title_type = this.$route.query.title_type ? this.$route.query.title_type : '';
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;

			this.$http.post({
				url: '/News/listPage',
				data: {...this.searchForm, pageSize: this.pageSize},
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			});
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: {...this.searchForm}});
		},
		onSearch(e) {

		}
	},
}
</script>
