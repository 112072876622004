<template>
    <lee-page code="news" bg-img="/images/banner/2.jpg" height="528" titleClass="margin-top-200 light f70" title-pos="left" title="资讯" sub-title="Information" :bread="[{title:'资讯'}]">
        <div class="bg-img-5"  style="background-color:#f8f9fa">
            <div class="wrap1100 padding-top-100 padding-bottom-100" :class="$isMobile ? '' : 'wrap1100'">
              <div class="cl"></div>
              <template v-if="list.length > 0">
								<el-row :gutter="20" class="mg-t-30">
									<el-col :span="6" v-for="(item,index) in list">
										<div class="hide hand rel" style="width:100%;height:206px;background-size:100% 100%;background-repeat:no-repeat;padding:20px;border-radius: 10px;" @click="$router.push({path:'/news/list/'+item.id})" :style="{backgroundImage:'url('+$utils.formatImagePath(item.icon)+')'}">
											<div class="f30 dark-0 margin-top-30 f-lq">{{ item.name }}</div>
											<div class="f12 margin-top dark-2 bg-light inline-block" style="padding:5px 10px;border-radius: 5px;">了解更多&gg;</div>
										</div>
<!--										<div class="box-shadow-green hide hand rel" style="height:200px;background: #fff;padding:20px;border-radius: 10px;" @click="$router.push({path:'/news/list/'+item.id})">-->
<!--											<div class="f20 green f-b">{{ item.name }}</div>-->
<!--											<div class="f12 margin-top dark-2">了解更多&gg;</div>-->
<!--											<img class="abs" :src="$utils.formatImagePath(item.icon)" style="opacity:.5;width: 200px;right:-60px;bottom:-60px;" v-if="item.icon"/>-->
<!--										</div>-->
									</el-col>
								</el-row>
              </template>
              <el-empty description="暂无数据" v-else></el-empty>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "xifenIndex",
    data(){
      return{
        list:[],
        searchForm:{
          akey:'news',
					pid:'0',
					state:1,
					orderBy:'sort',
					sortBy:'asc'
        },
        totalCount:0
      }
    },
    created() {
        // this.id = this.formData.tid = this.$route.params.id ? this.$route.params.id : '';
    },
    mounted() {
        this.getList();
    },
    methods:{
      getList(){
        let _this = this;

        this.$http.post({
          url:'/Type/listAll',
          data:this.searchForm,
          success(res){
            _this.list = res;
          }
        })
      }
    }
}
</script>
