<template>
	<lee-page code="news" bg-img="/images/bg_head_5.jpg" title="资讯" sub-title="News & Information" :search="true" height="280" @onSearch="onSearch" :bread="[{title:'资讯',url:'/news'},{title:typeInfo.name}]" v-if="typeInfo">
		<div class="bg-img-2" style="background-color:#f8f9fa">
			<div class="wrap1100 padding-top-50 padding-bottom-100">
				<div class="cl"></div>
				<template v-if="list.length > 0">
					<div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{ total }}</b> 条相关内容</div>
					<div class="box-shadow bg-light margin-bottom-20 hand rel" style="padding:10px;" v-for="(item,index) in list" @click="$router.push({path:'/news/detail/'+item.id})">
						<div v-if="item.sort > 0" style="background-color: #F56C6C;color:#fff;top:0;left:10px;padding:0 10px;" class="abs f14">置顶</div>
						<img class="z" :src="$utils.formatImagePath(item.head_img)" style="width: 120px;height: 120px;"/>
						<div style="padding-left:130px;height: 120px;">
							<div class="hide">
								<div class="green-line">
									<img src="/images/icon/icon_30.png" height="22" class="z"/>
									<div v-html="item.title" class="f-b" style="padding-left:30px;line-height: 22px;"></div>
								</div>
								<div class="f14 dark-3 text-line-2" v-html="item.content"></div>
								<div class="f14 margin-top" style="color: #ccc">{{item.create_time}}</div>
							</div>
						</div>
					</div>
					<div class="text-center margin-top-100">
						<el-pagination
							:hide-on-single-page="true"
							background
							layout="pager"
							:page-size="pageSize"
							:current-page.sync="searchForm.page"
							:total="total"
							@current-change="pageChange">
						</el-pagination>
					</div>
				</template>
				<el-empty description="未查询到相关内容" v-else></el-empty>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			list: [],
			pageSize: 10,
			total: 0,
			searchForm: {
				page: 1,
				title: '',
				title_type: '',
				type_news_id:'',
				draft:'0',
				orderBy:'sort desc,create_time',
				sortBy:'desc'
			},
			typeInfo:null
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.searchForm,...this.$route.query};
		}

		if(this.$route.params.type){
			this.searchForm.type_news_id = this.$route.params.type;
			this.getType()
		}

		this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList() {
			let _this = this;

			this.$http.post({
				url: '/News/listPage',
				data: {...this.searchForm, pageSize: this.pageSize},
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			})
		},
		getType() {
			let _this = this;

			this.$http.post({
				url: '/Type/detail',
				data:{id:this.searchForm.type_news_id},
				success(res) {
					_this.typeInfo = res;
				}
			})
		},
		pageChange(page) {
			this.searchForm.page = page;

			this.$router.replace({query: {...this.searchForm}});
			// this.getList();
		},
		onSearch(k) {
			console.log(k);
			this.searchForm.title = k.title;
			this.searchForm.title_type = k.title_type;

			this.$router.replace({query: {...this.searchForm}});
		}
	}
}
</script>
