<template>
	<lee-page bg-img="/images/bg_head_9.jpg" title="奖项申报" height="250" :bread="[{title:'奖项申报',url:'/survey'},{title:'奖项信息'}]">
		<div class="bg-img-11"  style="background-color:#f8f9fa">
			<div class="wrap1100 padding-top-100 ">
				<div class="cl"></div>
				<div class="bg-light box-shadow border-radius-5 rel" style="padding:30px;">
					<template v-if="info">
						<div class="text-center" style="font-size: 24px;font-weight: bold;padding:30px 0;">{{info.title}}</div>
						<div class="cl" v-if="info.has_child === 0">
							<div class="z text-right dark-2" style="width: 150px;">奖项说明：</div>
							<div class="z" style="white-space: break-spaces;width: 890px;">{{info.clean_des}}</div>
						</div>
						<div class="cl margin-top-20" v-if="info.has_child === 0">
							<div class="z text-right dark-2" style="width: 150px;">评选范围：</div>
							<div class="z" style="white-space: break-spaces;width: 890px;">{{info.fanwei}}</div>
						</div>
						<div class="cl margin-top-20" v-if="info.has_child === 0">
							<div class="z text-right dark-2" style="width: 150px;">评选条件：</div>
							<div class="z" style="white-space: break-spaces;width: 890px;">{{info.tiaojian}}</div>
						</div>
						<template v-if="info.has_child === 0">
							<div class="cl margin-top-20" v-if="info.files && info.files.length > 0">
								<div class="z text-right dark-2" style="width: 150px;">附件：</div>
								<div class="z" style="white-space: break-spaces;width: 890px;">
									<div v-for="(item,index) in info.files" style="margin-bottom:10px;display: block;">
										<a :href="$utils.formatImagePath(item.url)" target="_blank" style="display:inline-block;border-radius: 15px;height: 30px;background-color: #3a8ee6;color:#fff !important;font-size: 14px;padding:5px 10px;">{{item.name}}</a>
									</div>
								</div>
							</div>
						</template>
						<template v-if="info.has_child === 0">
							<div class="text-center" style="margin-top:50px;">
								<el-button @click="goBack">返回</el-button>
								<el-button type="primary" @click="toStart" :disabled="info.state !== 1">开始申报</el-button>
							</div>
						</template>
						<template v-else>
							<template v-if="list.length > 0">
								<el-row :gutter="20">
									<el-col :span="8" v-for="(item,index) in list">
										<div class="box-shadow bg-light margin-bottom-20 hand rel" @click="toInfo(item)" style="height: 350px;overflow: hidden;width: 100%;">
											<img :src="$utils.formatImagePath(item.head_img)" style="width: 100%;height: 250px;"/>
											<div class="hide">
												<div class="margin">
													<div class="green-line">
														<div class="dark-0 vm f16 text-line-1">{{item.title}}</div>
													</div>
													<div class="margin-top f14 dark-3" v-if="item.start_time">发布时间：{{item.start_time}}</div>
												</div>
											</div>
											<span v-if="item.state === 1" class="bg-green light f14" style="position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">进行中</span>
											<span v-if="item.state === 2" class="f14" style="background-color:#eee;color:#777;position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">已结束</span>
											<span v-if="item.state === 0" class="f14" style="background-color:#ffb700;color:#fff;position: absolute;right:20px;top:0;border-radius:0 0 3px 3px;padding:3px 10px;">未开始</span>
										</div>
									</el-col>
								</el-row>
							</template>
						</template>
						<template v-if="info.has_child === 0">
							<span v-if="info.state === 1" class="bg-green light f16" style="position: absolute;left:90px;top:0;border-radius:0 0 3px 3px;padding:5px 10px;">进行中</span>
							<span v-if="info.state === 2" class="f16" style="background-color:#eee;color:#777;position: absolute;left:90px;top:0;border-radius:0 0 3px 3px;padding:5px 10px;">已结束</span>
							<span v-if="info.state === 0" class="f16" style="background-color:#ffb700;color:#fff;position: absolute;left:90px;top:0;border-radius:0 0 3px 3px;padding:5px 10px;">未开始</span>
						</template>
					</template>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "done",
	data() {
		return {
			id:'',
			info:null,
			list:[]
		}
	},
	created() {
		this.id = this.$route.params.id ? this.$route.params.id : ''
	},
	mounted() {
		if(this.id){
			this.getDetail();
		}
	},
	methods: {
		toInfo(item){
			let _this = this;

			this.$router.push({path:'/survey/info/'+item.id});
		},
		toStart(){
			let _this = this;

			if(this.info.state === 0){
				_this.$alert('此奖项申报未开启', '提示', {
					confirmButtonText: '知道了',
					showClose:false,
					callback: action => {}
				});
				return;
			}

			if(this.info.state === 2){
				_this.$alert('此奖项申报已结束', '提示', {
					confirmButtonText: '知道了',
					showClose:false,
					callback: action => {}
				});
				return;
			}

			this.$router.push({path:'/survey/detail/'+this.id});
		},
		goBack(){
			this.$router.back();
		},
		getList(){
			let _this = this;

			this.$http.post({
				url:'/SurveyTitle/listAll',
				data:{pid:this.id,orderBy:'field(state,1,2,0)',sortBy:'asc'},
				success(res){
					_this.list = res;
				}
			})
		},
		getDetail(){
			let _this = this;
			this.$http.post({
				url:'/SurveyTitle/detail',
				data:{id:this.id},
				success(res){
					_this.info = res;
					if(res.has_child === 1){
						_this.getList();
					}
				},
				fail(err){
					if(err.code === -100){
						_this.$alert(err.msg, '提示', {
							confirmButtonText: '立即返回',
							showClose:false,
							callback: action => {
								_this.$router.back();
							}
						});
					}else{
						_this.$message.error(err.msg);
					}
				}
			})
		}
	}
}
</script>
