<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">冷库建设相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1 ? data.now.content1 : ''">
			{{ data.now.content1 ? data.now.content1 : '' }}
		</div>
		<div class="margin-top-20">
			<el-row :gutter="10">
				<el-col :span="!!searchQuarterData ? 12 : 24">
					<div class="text-center margin-top-20">
						<lee-bar-echarts
							key="bar1"
							ref="bar1"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿元）"
							height="250px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
				<el-col :span="12" v-if="!!searchQuarterData">
					<div class="text-center margin-top-20">
						<lee-bar-echarts
							key="bar1_1"
							ref="bar1_1"
							:grid-bottom="30"
							:grid-top="30"
							left-name="（亿元）"
							height="250px"
							show-legend
						></lee-bar-echarts>
					</div>
				</el-col>
			</el-row>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按建设类型分析</span>
			</div>
			<div class="text-center margin-top-20">
				<el-row>
					<el-col :span="12">
						<lee-bar-echarts-hor
							key="bar2"
							ref="bar2"
							:grid-bottom="10"
							grid-right="10%"
							:grid-top="20"
							height="350px"
							:show-legend="false"
							legend-orient="vertical"
							legend-pos="right"
							:show-left="true"
							:show-right="false"
						></lee-bar-echarts-hor>
					</el-col>
					<el-col :span="12">
						<lee-pie-echarts
							key="pie2_2"
							ref="pie2_2"
							:grid-bottom="30"
							height="350px"
							:show-legend="false"
							label-format="{b}:{d}%"
							label-pos="outside"
							:radius="['0%','50%']"></lee-pie-echarts>
					</el-col>
				</el-row>
			</div>
<!--			<div class="text-center margin-top-20">-->
<!--				<lee-bar-echarts-->
<!--					key="bar2"-->
<!--					ref="bar2"-->
<!--					:grid-bottom="30"-->
<!--					height="350px"-->
<!--					show-legend-->
<!--				></lee-bar-echarts>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
export default {
	name: "Build",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuBuild/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuBuild/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLengkuBuild/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata1_1 = {bar1: [],xaxis: []};
					let _bardata2 = {bar1: [], xaxis: ['食品/预制菜产业园','物流园','冷链物流园']};
					let _pie2_2Data = [];

					for (let i = 0; i < res.listAll.length; i++) {
						_bardata1_1.bar1.push(res.listAll[i].amount);
						_bardata1_1.xaxis.push(res.listAll[i].time_str);
					}

					for (let i = 0; i < res.list.length; i++) {
						_bardata1.bar1.push(res.list[i].amount);
						_bardata1.line1.push(res.list[i].amount_rate);
						_bardata1.xaxis.push(res.list[i].time_str);
					}

					_bardata2.bar1.push(parseInt(res.now.yzccyy));
					_bardata2.bar1.push(parseInt(res.now.wly));
					_bardata2.bar1.push(parseInt(res.now.llwly));

					_pie2_2Data.push({value:parseInt(res.now.yzccyy),name:'食品/预制菜产业园'});
					_pie2_2Data.push({value:parseInt(res.now.wly),name:'物流园'});
					_pie2_2Data.push({value:parseInt(res.now.llwly),name:'冷链物流园'});

					setTimeout(()=>{
						if(!!_this.searchQuarterData){
							if (_this.$refs.bar1_1) {
								_this.$refs.bar1_1.initData(
									[
										{name: '建设投资金额', type: 'bar', color: 'blue', data: _bardata1_1.bar1, dw: '亿元'},
									],
									_bardata1_1.xaxis
								);
							}
						}
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '建设投资金额', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '建设类型', type: 'bar', color: 'most', data: _bardata2.bar1, dw: '个'},
								],
								_bardata2.xaxis
							);
						}

						if(_this.$refs.pie2_2) {
							_this.$refs.pie2_2.initData(_pie2_2Data);
						}
					},200)
				}
			})
		},
	}
}
</script>
