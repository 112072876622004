<template>
  <el-dialog append-to-body :visible.sync="openDialog" title="登录" width="500px" @closed="hideDialog">
    <el-form :model="formData" status-icon :rules="rules" ref="ruleForm" label-width="auto" class="margin-top-30">
      <el-form-item label="" prop="username">
        <el-input v-model="formData.username" autocomplete="off" class="lee-input mobile" placeholder="用户名">
          <i class="el-icon-user el-input__icon" slot="prefix"></i>
        </el-input>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input type="password" v-model="formData.password" autocomplete="off" class="lee-input mobile" placeholder="密码">
          <i class="el-icon-lock el-input__icon" slot="prefix"></i>
        </el-input>
      </el-form-item>
      <div class="cl">
        <a href="javascript:void(0);" class="dark-5 y under" @click="$router.push({path:'/findpwd'})">忘记密码？</a>
      </div>
      <el-form-item class="margin-top-30">
        <el-row :gutter="20">
          <el-col :span="12"><el-button type="success" class="block" @click="login">登录</el-button></el-col>
          <el-col :span="12"><el-button class="block button-green" plain @click="$router.push({path:'/register'})">注册</el-button></el-col>
        </el-row>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "LoginDialog",
  data() {
    return {
      openDialog:false,
      formData:{
        username:'',
        password:''
      },
      rules: {
        username:[{ required: true, message:'请输入用户名'}],
        password:[{ required: true, message:'请输入密码'}],
      }
    }
  },
  props:{
    show:{
      type:Boolean,
      default:false
    }
  },
  model:{
    prop:'show',
    event:'change'
  },
  created() {
    this.openDialog = this.show;
  },
  mounted() {
  },
  methods: {
    showDialog(){
      this.openDialog = true;
    },
    hideDialog(){
      this.openDialog = false;
      this.$emit('change',this.openDialog);
    },
    login(){
      let _this = this;
      this.$http.post({
        url:'/Common/login',
        data:this.formData,
        success(res){
          _this.$user.setMobile(res.mobile);
          _this.$user.setOpenId(res.openid);
          _this.$user.setAvatar(res.avatar);
          _this.$user.setUid(res.uid);
          _this.$user.setUserName(res.username);
          _this.$user.setToken(res.token);
          _this.$user.setEmail(res.email);
          _this.$user.setContact(res.name);
          _this.$user.setSex(res.sex);
          _this.$user.setNickName(res.nickname);
          _this.$user.setVipState(res.vip_state);
          _this.$user.setCompany(res.company);
          _this.$user.setJob(res.job);

					_this.$user.getUserInfo(()=>{
						_this.hideDialog();

						_this.$emit('onLogin');

						location.reload();
					});
        }
      })
    }
  },
  watch:{
    show(n,o){
      this.openDialog = n;
    }
  }
}
</script>
