<template>
	<div class="margin-top-100">
		<div class="text-center f38 dark-1 f-lq divider-green">最新资讯</div>
		<div class="text-center f24 f-thin dark-3">Latest Standards</div>
		<div class="margin-top-50 margin-auto" style="width: 1100px;">
			<div class="cl margin-bottom-20 hand hide" style="padding:15px;height:100px;background-color: #f4f4f4;" v-for="(item,index) in list" @click="$router.push({path:'/news/detail/'+item.id})">
				<div class="z hide" style="width: 100px;height: 70px;border-right: 1px solid #ccc;">
					<div class="dark-2 f28" style="line-height: 40px">{{$moment(item.create_time).format('DD')}}</div>
					<div class="dark-3 f14" style="line-height: 20px">{{$moment(item.create_time).format('YYYY.M')}}</div>
				</div>
				<div class="z padding-left-right-30 hide" style="width: 900px;height: 70px;">
					<div class="f18 text-line-2 f-b" style="line-height: 20px;">{{item.title}}</div>
					<div class="f14 dark-3 text-line-1 margin-top" style="line-height: 20px;">{{item.content}}</div>
				</div>
				<div class="y f30 dakr-2" style="line-height: 70px;">&gt;</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "news",
	data() {
		return {
			list:[]
		}
	},
	created() {
	},
	mounted() {
		this.getList();
	},
	methods: {
		getList(){
			let _this = this;
			this.$http.post({
				url: '/News/listAll',
				data: {orderBy:'sort desc,create_time',sortBy:'desc',limit:6,draft:'0'},
				success(res) {
					_this.list = res;
				}
			});
		},
	}
}
</script>
