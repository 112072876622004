<template>
    <lee-page bg-img="/images/bg_head_4.jpg" title="问答中心" sub-title="Q&A Center" des="" :search="false">
        <div class="margin-top margin-auto" style="width: 866px" v-if="!$isMobile" slot="search">
            <lee-search @search="onSearch" @clear="clear"></lee-search>
        </div>
        <div style="margin-top:-45px;height: 90px;" class="wrap1100 margin-auto cl">
            <div class="z bg-light box-shadow border-radius hide" style="width: 410px;height: 90px;">
                <div class="hand z text-center f24 f-lq" style="width: 205px;line-height: 90px;" :class="searchForm.orderBy === item.value ? 'bg-green light border-radius' : 'dark-2'" v-for="(item,index) in orderByList" @click="changeQaType(item)">
                    {{item.title}}
                </div>
            </div>
            <div class="y cl">
                <div class="z bg-blue hand box-shadow padding border-radius margin-right" style="height: 90px;line-height: 70px;" @click="toShowAddQuestion">
                    <img src="/images/icon/icon_19.png" width="65"/>
                    <span class="light f24 margin-left-20 f-lq" style="vertical-align: middle;margin-right: 40px;">马上提问</span>
                </div>
<!--                <div class="z bg-green hand box-shadow padding border-radius" style="height: 90px;line-height: 70px;" @click="$router.push({path:'/qa/me'})">-->
<!--                    <img src="/images/icon/icon_20.png" width="65"/>-->
<!--                    <span class="light f24 margin-left-20 f-lq" style="vertical-align: middle;margin-right: 40px;">查看我的提问</span>-->
<!--                </div>-->
              <div class="z box-shadow border-radius hide" style="width:300px;height: 90px;line-height: 90px;">
                <div class="inline-block bg-green f-lq text-center light f24 hand" style="width: 50%" @click="$router.push({path:'/qa/me'})">我的提问</div>
                <div class="inline-block bg-blue f-lq text-center light f24 hand" style="width: 50%" @click="$router.push({path:'/qa/myanswer'})">我的回答</div>
              </div>
            </div>
        </div>
        <div class="bg-img-2 " style="background-color: #f8f9fa;margin-top: -45px;">
            <div class="cl"></div>
            <div class="wrap1100 margin-top-30">
                <template v-if="list.length > 0">
                    <div class="bg-light border-radius-5 margin-bottom qa-list hide" v-for="(item,index) in list">
                        <div :class="index === activeIndex ? 'on' : ''" class="title cl bg-light padding-20" style="height: 80px;line-height: 40px;">
                            <img src="/images/icon/icon_q.png" height="30" class="z margin-top-5"/>
                            <span class="z text-line-1 margin-left f16 dark-1" v-html="(parseFloat(item.price) > 0 ? '<span class=\'f12 bg-red border-radius light padding-5 margin-right\'>奖励金额：￥<b class=\'f14\'>'+item.price+'</b></span>' : '') + '问题标题：'+item.title" style="max-width: 850px;"></span>
                            <div class="y">
                                <span class="inline-block vm orange margin-right hand" @click="toDel(item.id)" v-if="item.uid === uid">
                                    <i class="el-icon-delete"></i>
                                    删除
                                </span>
                                <span class="inline-block vm green hand" @click="activeIndex = index">
                                    <i :class="index === activeIndex ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i>
                                    详情
                                </span>
                            </div>
                        </div>
                        <div class="" :class="index === activeIndex ? '' : 'none'">
                            <div class="padding-20" v-html="'问题描述：'+item.content"></div>
                            <div class="bg-success-light padding-top-bottom padding-left-right-20">
                                <div v-if="item.answer_list.length > 0">
                                    <div class="dark-2">回答列表：</div>
                                    <div class="cl border-bottom-1 border-dark-6" v-for="(t,i) in item.answer_list">
                                        <div class="z padding-top-bottom" style="max-width: 800px" v-html="(i+1)+'. ' + (t.uid === uid ? '<b class=\'orange f-n margin-right\'>[你的回答]</b>' : '') + t.content+(t.bid_state === 0 && t.uid !== uid ? '......<b class=\'dark-3 f-n\'>[需购买]</b>' : '')"></div>
                                        <div class="y">
                                            <el-button class="orange" type="text" icon="el-icon-delete" @click="toDelAnswer(t.id)" v-if="t.uid === uid">删除</el-button>
                                            <el-button class="gablue" type="text" icon="el-icon-goods" @click="toBuyAnswer(t,item)" v-if="t.uid !== uid && t.bid_state === 0">购买</el-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center margin-top" v-if="item.self_answer === 0">
                                    <el-button size="medium" type="success" class="box-shadow-green" @click="toShowAddAnswer(item)">立即回答</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center margin-top-100">
                        <el-pagination
                            background
                            layout="pager, jumper"
                            :page-size="pageSize"
                            :current-page.sync="searchForm.page"
                            :total="total"
                            @current-change="pageChange">
                        </el-pagination>
                    </div>
                </template>
                <el-empty description="暂无数据" v-else></el-empty>
            </div>
        </div>
        <el-dialog
            title="提问"
            :visible.sync="showAddQa">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" :show-message="true" :inline-message="true">
                <el-form-item label="" prop="title" class="margin-bottom-0">
                    <div class="green">问题标题</div>
                    <el-input v-model="formData.title" class="lee-input mobile" placeholder="输入标题"></el-input>
                </el-form-item>
                <el-form-item label="" prop="content" class="margin-bottom-0">
                    <div class="green">问题描述</div>
                    <div class="bg-gray-4 cl">
                        <el-input
                            class="lee-textarea"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                            v-model="formData.content">
                        </el-input>
                    </div>
                </el-form-item>
                <el-form-item label="" prop="price" class="margin-bottom-0">
                    <div class="green">奖励金额(元)：</div>
                    <el-input v-model="formData.price" class="lee-input mobile" placeholder="输入价格"></el-input>
                </el-form-item>
            </el-form>
            <div class="cl" slot="footer">
                <el-button size="medium" type="success" class="box-shadow-green" @click="addQuestion('form')">提交确认</el-button>
            </div>
        </el-dialog>
        <el-dialog title="回答" :visible.sync="showAddAnswer" :close-on-click-modal="false" :close-on-press-escape="false">
            <el-form :model="answerForm" status-icon :rules="answerformrules" ref="answerform" label-width="auto" :show-message="true" :inline-message="true">
                <el-form-item label="" prop="title" class="margin-bottom-0">
                    <div class="dark-1">问题标题：{{answerForm.title}}</div>
                    <div class="dark-2" v-if="answerForm.qa_content">问题描述：{{answerForm.qa_content}}</div>
                </el-form-item>
                <el-form-item label="" prop="content" class="margin-bottom-0">
                    <div class="green">回答内容</div>
                    <div class="bg-gray-4 cl">
                        <el-input
                            class="lee-textarea"
                            type="textarea"
                            :rows="4"
                            placeholder="请输入内容"
                            v-model="answerForm.content">
                        </el-input>
                    </div>
                </el-form-item>
            </el-form>
            <div class="cl" slot="footer">
                <el-button size="medium" type="info" plain class="box-shadow-30" @click="showAddAnswer = false">关闭</el-button>
                <el-button size="medium" type="success" class="box-shadow-green" @click="addAnswer('answerform')">提交确认</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="支付"
            :visible.sync="show" @close="closePay">
            <lee-pay-view :name="buyData.title" :price="buyData.price" title="购买答案" sub-title="答案金额" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode" v-if="buyData"></lee-pay-view>
        </el-dialog>
    </lee-page>
</template>

<script>
export default {
    name: "Index",
    data(){
        return{
            activeIndex:0,
            show:false,
            showAddQa:false,
            showAddAnswer:false,
            buyData:null,
            qrcode:'',
            time:null,
            order_id:'',
            formData:{
                content:'',
                title:'',
                price:''
            },
            answerForm:{
                tid:'',
                content:'',
                title:'',
                qa_content:''
            },
            searchForm:{
                page:1,
                title:'',
                orderBy:'create_time'
            },
            list:[],
            pageSize:15,
            total:0,
            orderByList:[
                {title:'最新问题',value:'create_time'},
                {title:'热门问题',value:'answer_count'},
            ],
            rules: {
                title:[{ required: true, message:'请输入问题标题'}],
                content:[{ required: true, message:'请输入问题内容'}],
                price:[
                    { required: true, message:'请输入奖励金额'},
                    {message: '请填写正确的金额',pattern:this.$regexp.money}
                ],
            },
            answerformrules:{
                tid:[{ required: true, message:'请选择问题'}],
                content:[{ required: true, message:'请输入回答内容'}],
            },
            uid:this.$user.getUid()
        }
    },
    created() {
        if(!this.$utils.isEmptyObject(this.$route.query)){
            this.searchForm = {...this.$route.query};
        }

        this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
        this.searchForm.orderBy = this.$route.query.orderBy ? this.$route.query.orderBy : 'create_time';
    },
    mounted() {
        this.getList();
    },
    methods:{
        toShowAddQuestion(){
          if(!this.uid){
            this.$message.error('请先登录');
            return;
          }

          this.formData.content = '';
          this.formData.title = '';
          this.formData.price = '';

          this.showAddQa = true;
        },
        closePay(){
            this.show = false;
            clearInterval(this.time);
            this.time = null;
            this.qrcode = '';
            this.order_id = '';
        },
        toBuyAnswer(e,e2){
                // this.show = true;
            this.buyData = e;

            this.buyData.price = e2.price;

            this.getPayCode();
        },
        getPayCode(){
            let _this = this;
            this.$http.post({
                url:'/Order/getPayCode',
                data:{id:this.buyData.id,order_type:'answer'},
                success(res){
                    _this.qrcode = res.qrcode;
                    _this.order_id = res.order_id;
                    _this.show = true;
                    _this.timeDown('checkState');
                }
            })
        },
        timeDown(type){
            if(type === 'checkState') {
                this.time = setInterval(this.checkState, 3000)
            }
        },
        checkState(){
            let _this = this;
            this.$http.post({
                url:'/Common/checkOrder',
                data:{id:this.order_id},
                success(res){
                    if(res.state === 2){
                        clearInterval(_this.time);
                        _this.time = null;
                        _this.show = false;
                        _this.$message.success('支付成功');

                        _this.getList();
                    }
                }
            })
        },
        toShowAddAnswer(e){
            if(!this.uid){
                this.$message.error('请先登录');
                return;
            }

            if(this.uid){
                if(e.uid === this.uid){
                    this.$message.error('不能回答自己的提问');
                    return;
                }
            }

            this.answerForm.content = '';
            // this.answerForm.price = '';
            this.answerForm.title = e.title;
            this.answerForm.qa_content = e.content;
            this.answerForm.tid = e.id;

            this.showAddAnswer = true;
        },
        toDel(id){
            let _this = this;
            this.$confirm('是否删除此问题?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post({
                    url:'/Question/del',
                    data:{id:id},
                    success(res){
                        _this.$message.success('删除成功');
                        _this.getList();
                    }
                })
            })
        },
        toDelAnswer(id){
            let _this = this;
            this.$confirm('是否删除此回答内容?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post({
                    url:'/AuthAnswer/del',
                    data:{id:id},
                    success(res){
                        _this.$message.success('删除成功');
                        _this.getList();
                    }
                })
            })
        },
        changeQaType(v){
            // console.log(v);
            this.searchForm.orderBy = v.value;

            // this.$router.replace({query:{...this.searchForm}});
            this.getList();
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm}});
        },
        getList(){
            let _this = this;
            this.$http.post({
                url:'/Question/listPage',
                data:this.searchForm,
                success(res){
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            })
        },
        addAnswer(formName){
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/AuthAnswer/add',
                        data: _this.answerForm,
                        success(res) {
                            _this.answerForm.content = '';
                            // _this.answerForm.price = '';
                            _this.answerForm.title = '';
                            _this.answerForm.qa_content = '';
                            _this.answerForm.tid = ''

                            _this.showAddAnswer = false;

                            _this.getList();

                            _this.$message.success('回答成功');
                        }
                    })
                }
            })
        },
        addQuestion(formName){
            let _this = this;
            this.$refs[formName].validate(valid => {
                if (valid) {
                    _this.$http.post({
                        url: '/Question/add',
                        data: _this.formData,
                        success(res) {
                            _this.searchForm.page = 1;
                            _this.formData = {title:'',content:'',price:''};
                            _this.showAddQa = false;
                            // _this.$router.replace({query:{...this.searchForm}});
                            _this.getList();
                        }
                    })
                }else{
                  _this.$message.error('请填写完整信息');
                }
            })
        },
        clear(){
            this.$router.replace({query:{title:''}});
            this.$router.replace({query:{...this.searchForm}});
        },
        onSearch(res){
            this.searchForm.title = res;
            let _this = this;

            if(this.searchForm.title) {
                _this.$http.post({
                    url: '/Question/search',
                    data: {...this.searchForm},
                    success(res) {
                        _this.list = res.list;
                        _this.total = res.totalCount;
                    }
                })
            }else{
                this.getList();
            }
        }
    }
}
</script>
