<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">冷库供需相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1 ? data.now.content1 : ''">
			{{ data.now.content1 ? data.now.content1 : '' }}
		</div>
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar1"
						ref="bar1"
						:grid-bottom="30"
						:grid-top="30"
						left-name="（万立方米）"
						height="350px"
						show-legend
					></lee-bar-echarts>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar2"
						ref="bar2"
						:grid-bottom="30"
						:grid-top="30"
						left-name="（万立方米）"
						height="350px"
						show-legend
					></lee-bar-echarts>
				</div>
			</el-col>
		</el-row>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">求租量分析</span>
			</div>
			<div class="margin-top-20" v-if="data && data.now && data.now.content2" v-html="data.now.content2 ? data.now.content2 : ''">
				{{data.now.content2 ? data.now.content2 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<el-row>
					<el-col :span="12">
						<lee-bar-echarts-hor
							key="bar3"
							ref="bar3"
							:grid-bottom="10"
							:grid-top="10"
							grid-right="10%"
							height="350px"
							:show-legend="false"
							legend-orient="vertical"
							legend-pos="right"
							:show-left="true"
							:show-right="false"
						></lee-bar-echarts-hor>
					</el-col>
					<el-col :span="12">
						<lee-pie-echarts
							key="pie3_3"
							ref="pie3_3"
							:grid-bottom="30"
							height="350px"
							:show-legend="false"
							label-format="{b}:{d}%"
							label-pos="outside"
							:radius="['30%','50%']"></lee-pie-echarts>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">出租量分析</span>
			</div>
			<div class="margin-top-20" v-if="data && data.now && data.now.content3" v-html="data.now.content3 ? data.now.content3 : ''">
				{{data.now.content3 ? data.now.content3 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<el-row>
					<el-col :span="12">
						<lee-bar-echarts-hor
							key="bar4"
							ref="bar4"
							:grid-bottom="10"
							:grid-top="10"
							grid-right="10%"
							height="350px"
							:show-legend="false"
							legend-orient="vertical"
							legend-pos="right"
							:show-left="true"
							:show-right="false"
						></lee-bar-echarts-hor>
					</el-col>
					<el-col :span="12">
						<lee-pie-echarts
							key="pie4_4"
							ref="pie4_4"
							:grid-bottom="30"
							height="350px"
							:show-legend="false"
							label-format="{b}:{d}%"
							label-pos="outside"
							:radius="['30%','50%']"></lee-pie-echarts>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Gongxu",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom: {
				year: '',
				times: '',
				time_type: '',
			},
			searchData: '',
			searchQuarterData: ''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e) {
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e) {
			this.getQuarter()
		},
		onChangeQuarter(e) {
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuGongxu/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuGongxu/getQuarter',
				data: {year: this.searchData},
				success(res) {
					if (res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					} else {
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if (this.searchQuarterData && this.searchQuarterData !== '0') {
				_where.times = [this.searchQuarterData, '<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData;
				}
			} else {
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLengkuGongxu/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata2 = {bar1: [], line1: [], xaxis: []};
					let _bardata3 = {bar1: [], xaxis: []};
					let _pie3_3Data = [];
					let _bardata4 = {bar1: [], xaxis: []};
					let _pie4_4Data = [];

					// for (let i = 0; i < res.list.length; i++) {
					// 	_bardata1.bar1.push(res.list[i].total);
					// 	_bardata1.line1.push(res.list[i].total_rate);
					// 	_bardata1.xaxis.push(res.list[i].time_str);
					// 	_bardata2.bar1.push(res.list[i].amount);
					// 	_bardata2.line1.push(res.list[i].amount_rate);
					// 	_bardata2.xaxis.push(res.list[i].time_str);
					// }
					for (let i = 0; i < res.listAll.length; i++) {
						_bardata1.bar1.push(res.listAll[i].total);
						_bardata1.xaxis.push(res.listAll[i].time_str);
						_bardata2.bar1.push(res.listAll[i].amount);
						_bardata2.xaxis.push(res.listAll[i].time_str);
					}

					for (let i = 0; i < res.in.length; i++) {
						_bardata3.bar1.push(res.in[i].value);
						_bardata3.xaxis.push(res.in[i].name);

						_pie3_3Data.push({value: parseFloat(res.in[i].value), name: res.in[i].name});
					}

					for (let i = 0; i < res.out.length; i++) {
						_bardata4.bar1.push(res.out[i].value);
						_bardata4.xaxis.push(res.out[i].name);

						_pie4_4Data.push({value: parseFloat(res.out[i].value), name: res.out[i].name});
					}

					setTimeout(() => {
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷库求租量', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '万立方米'},
									// {name: '同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if (_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '冷库出租量', type: 'bar', color: 'blue', data: _bardata2.bar1, dw: '万立方米'},
									// {name: '同比', type: 'line', color: 'purple', data: _bardata2.line1, dw: '%'},
								],
								_bardata2.xaxis
							);
						}

						if (_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '求租量', type: 'bar', color: 'most', data: _bardata3.bar1, dw: '万立方米'},
								],
								_bardata3.xaxis
							);
						}
						if (_this.$refs.pie3_3) {
							_this.$refs.pie3_3.initData(_pie3_3Data);
						}

						if (_this.$refs.bar4) {
							_this.$refs.bar4.initData(
								[
									{name: '出租量', type: 'bar', color: 'most', data: _bardata4.bar1, dw: '万立方米'},
								],
								_bardata4.xaxis
							);
						}
						if (_this.$refs.pie4_4) {
							_this.$refs.pie4_4.initData(_pie4_4Data);
						}
					}, 200)
				}
			})
		},
	}
}
</script>
