<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">进出口品类相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
<!--		<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1">-->
<!--			{{data.now.content1 ? data.now.content1 : ''}}-->
<!--		</div>-->
		<div class="margin-top-20" v-if="data && data.now && data.text">
			{{data.text}}
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">蔬菜及制品</span>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="shucai_bar"
								ref="shucai_bar"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="shucai_bar_all"
								ref="shucai_bar_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="shucai_bar2"
								ref="shucai_bar2"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="shucai_bar2_all"
								ref="shucai_bar2_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">水果及制品</span>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="shuiguo_bar"
								ref="shuiguo_bar"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="shuiguo_bar_all"
								ref="shuiguo_bar_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="shuiguo_bar2"
								ref="shuiguo_bar2"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="shuiguo_bar2_all"
								ref="shuiguo_bar2_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">肉类及制品</span>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="rou_bar"
								ref="rou_bar"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="rou_bar_all"
								ref="rou_bar_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="rou_bar2"
								ref="rou_bar2"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="rou_bar2_all"
								ref="rou_bar2_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">水海产品及制品</span>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="shuichanpin_bar"
								ref="shuichanpin_bar"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="shuichanpin_bar_all"
								ref="shuichanpin_bar_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="shuichanpin_bar2"
								ref="shuichanpin_bar2"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="shuichanpin_bar2_all"
								ref="shuichanpin_bar2_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<div class="margin-top-20">
			<div style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">乳制品</span>
			</div>
			<div class="margin-top-20">
				<el-row :gutter="10">
					<el-col :span="!!searchQuarterData && searchQuarterData !== '0' ? 12 : 24">
						<div class="text-center">
							<lee-bar-echarts
								key="ruzhipin_bar"
								ref="ruzhipin_bar"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
					<el-col :span="12" v-if="!!searchQuarterData && searchQuarterData !== '0'">
						<div class="text-center">
							<lee-bar-echarts
								key="ruzhipin_bar_all"
								ref="ruzhipin_bar_all"
								:grid-bottom="30"
								height="300px"
								show-legend
								:grid-top="30"
								left-name="（万吨）"
							></lee-bar-echarts>
						</div>
					</el-col>
				</el-row>
			</div>
<!--			<div class="margin-top-20">-->
<!--				<el-row :gutter="10">-->
<!--					<el-col :span="!!searchQuarterData ? 12 : 24">-->
<!--						<div class="text-center">-->
<!--							<lee-bar-echarts-->
<!--								key="ruzhipin_bar2"-->
<!--								ref="ruzhipin_bar2"-->
<!--								:grid-bottom="30"-->
<!--								height="300px"-->
<!--								show-legend-->
<!--							></lee-bar-echarts>-->
<!--						</div>-->
<!--					</el-col>-->
<!--					<el-col :span="12" v-if="!!searchQuarterData">-->
<!--						<div class="text-center">-->
<!--							<lee-bar-echarts-->
<!--								key="ruzhipin_bar2_all"-->
<!--								ref="ruzhipin_bar2_all"-->
<!--								:grid-bottom="30"-->
<!--								height="300px"-->
<!--								show-legend-->
<!--							></lee-bar-echarts>-->
<!--						</div>-->
<!--					</el-col>-->
<!--				</el-row>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>
export default {
	name: "InOutZongzhi",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getMonth()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataInoutType/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getMonth();
				}
			});
		},
		getMonth() {
			let _this = this;

			this.$http.post({
				url: '/HydataInoutType/getMonth',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		formatBar(type, dw = '万吨') {
			let _bardata = {bar: [],line:[],};

			for (let i = 0; i < data.length; i++) {
				_bardata.bar.push(data[i].total);
			}

			return {name: dw, type: 'bar', color: 'green', data: _bardata.bar};
		},

		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'month';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataInoutType/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _arr = ['shucai','shuiguo','rou','shuichanpin','ruzhipin'];

					if(res && res.list.length > 0){
						for(let i=0;i < _arr.length;i++){
							let bardata = {bar: [],line:[], xaxis: []};
							let bardata1 = {bar: [],line:[], xaxis: []};

							for(let j=0;j < res.list.length;j++){
								let _data = res.list[j];
								bardata.bar.push(parseFloat(_data[_arr[i]+'_in']));
								bardata.line.push(parseFloat(_data['tongbi_'+_arr[i]+'_in']));
								bardata.xaxis.push(_data.time_str);

								bardata1.bar.push(parseFloat(_data[_arr[i]+'_out']));
								bardata1.line.push(parseFloat(_data['tongbi_'+_arr[i]+'_out']));
								bardata1.xaxis.push(_data.time_str);
							}

							setTimeout(()=>{
								if(_this.$refs[_arr[i]+'_bar']){
									_this.$refs[_arr[i]+'_bar'].initData(
										[
											{name: '进口量', type: 'bar', color: 'green', data: bardata.bar, dw: '万吨'},
											{name: '同比', type: 'line', color: 'purple', data: bardata.line, dw: '%'},
										],
										bardata.xaxis
									);
								}

								if(_this.$refs[_arr[i]+'_bar2']){
									_this.$refs[_arr[i]+'_bar2'].initData(
										[
											{name: '出口量', type: 'bar', color: 'blue', data: bardata1.bar, dw: '万吨'},
											{name: '同比', type: 'line', color: 'purple', data: bardata1.line, dw: '%'},
										],
										bardata1.xaxis
									);
								}
							},200)
						}
					}

					if(!!_this.searchQuarterData){
						if(res && res.listAll.length > 0){
							for(let i=0;i < _arr.length;i++){
								let bardata = {bar: [],xaxis: []};
								let bardata1 = {bar: [],xaxis: []};

								for(let j=0;j < res.listAll.length;j++){
									let _data = res.listAll[j];
									bardata.bar.push(parseFloat(_data[_arr[i]+'_in']));
									bardata.xaxis.push(_data.time_str);

									bardata1.bar.push(parseFloat(_data[_arr[i]+'_out']));
									bardata1.xaxis.push(_data.time_str);
								}

								setTimeout(()=>{
									if(_this.$refs[_arr[i]+'_bar_all']){
										_this.$refs[_arr[i]+'_bar_all'].initData(
											[
												{name: '进口量', type: 'bar', color: 'green', data: bardata.bar, dw: '万吨'},
											],
											bardata.xaxis
										);
									}

									if(_this.$refs[_arr[i]+'_bar2_all']){
										_this.$refs[_arr[i]+'_bar2_all'].initData(
											[
												{name: '出口量', type: 'bar', color: 'blue', data: bardata1.bar, dw: '万吨'},
											],
											bardata1.xaxis
										);
									}
								},200)
							}
						}
					}
				}
			})
		},
	}
}
</script>
