<template>
	<lee-page abs-bg-img="/images/bg_head_10.jpg" title-class="margin-top-100" :height="280" abs-title="扫码登录">
		<div class="bg-img-2 padding-top-100 padding-bottom-100">
			<div class="cl"></div>
			<div class="margin-auto bg-light border-radius-5 padding-20" style="width:500px;">
				<div class="green cl border-bottom padding-bottom">
					登录
					<el-button size="mini" type="success" round plain class="y vm" @click="$router.push({path:'/login'})">
						账号密码登录<i class="fa fa-qrcode margin-left" aria-hidden="true"></i></el-button>
				</div>
				<div class="text-center margin-top-30 margin-auto rel" :class="logined ? '' : 'show-refresh-qrcode'" style="width: 200px;" v-if="qrcode">
					<img :src="qrcode" class="border-green border-w-5 border-radius-5" height="200" width="200"/>
					<div class="hand show-qrcode none text-center light abs bg-dark-op" style="top:0;left:0;right:0;bottom: 0;" @click="getQrCode">
						<i class="margin-top-50 el-icon-refresh light f38"></i>
						<div class="margin-top-30">刷新二维码</div>
					</div>
					<div class="hand text-center light abs bg-dark-op" style="top:0;left:0;right:0;bottom: 0;" v-if="logined">
						<i class="margin-top-50 el-icon-success green f38"></i>
						<div class="margin-top-30">登录成功</div>
					</div>
				</div>
				<div class="dark-3 text-center margin-top-30 padding-bottom">手机微信扫一扫登录</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			qrcode: '',
			key: '',
			time: null,
			logined: false
		}
	},
	created() {

	},
	mounted() {
		this.getQrCode();

		// this.time = setInterval(this.qrLogin, 2000);
	},
	methods: {
		getQrCode() {
			let _this = this;
			this.$http.post({
				url: '/Common/getLoginQrCode',
				data: null,
				success(res) {
					_this.qrcode = res.qrcode;
					_this.key = res.key;

					if (_this.time) {
						clearInterval(_this.time);
						_this.time = null;
					}

					_this.time = setInterval(_this.checkLogin, 2000);
				}
			})
		},
		checkLogin() {
			//wx/user
			let _this = this;
			this.$http.post({
				url: '/Common/checkLogin',
				data: {key: this.key},
				success(res) {
					console.log(res);
					if (res.token) {
						_this.logined = true;
						_this.$user.setMobile(res.mobile);
						_this.$user.setOpenId(res.openid);
						_this.$user.setAvatar(res.avatar);
						_this.$user.setUid(res.uid);
						_this.$user.setUserName(res.username);
						_this.$user.setToken(res.token);
						_this.$user.setEmail(res.email);
						_this.$user.setContact(res.contact);
						_this.$user.setSex(res.sex);
						_this.$user.setNickName(res.nickname);
						_this.$router.back();

						if (_this.time) {
							clearInterval(_this.time);
							_this.time = null;
						}

						_this.$message.success('登录成功');
						_this.$router.replace({path: '/'});

					}
				}
			})
		}
	},
	beforeDestroy() {
		if (this.time) {
			clearInterval(this.time);
			this.time = null;
		}
	}
}
</script>
