<template>
	<div v-if="data">
		<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1">
			{{data.now.content1}}
		</div>
		<el-row :gutter="10">
			<el-col :span="!!searchQuarterData ? 12 : 24">
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar1"
						ref="bar1"
						:grid-bottom="30"
						:grid-top="30"
						right-name="同比"
						height="350px"
						show-legend
						left-name="（万亿元）"
					></lee-bar-echarts>
				</div>
			</el-col>
			<el-col :span="12" v-if="!!searchQuarterData">
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar1_1"
						ref="bar1_1"
						:grid-bottom="30"
						:grid-top="30"
						height="350px"
						show-legend
						left-name="（万亿元）"
					></lee-bar-echarts>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "IndexHyData1",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLenglian/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLenglian/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLenglian/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bar1data = {bar: [], line: [], xaxis: []};
					let _bar1data_1 = {bar: [], xaxis: []};

					for (let i = 0; i < res.list.length; i++) {
						_bar1data.bar.push(res.list[i].lenglian_amount);
						_bar1data.line.push(res.list[i].lenglian_rate);
						_bar1data.xaxis.push(res.list[i].time_str);
					}

					for (let i = 0; i < res.listAll.length; i++) {
						_bar1data_1.bar.push(res.listAll[i].lenglian_amount);
						_bar1data_1.xaxis.push(res.listAll[i].time_str);
					}

					setTimeout(()=>{
						if(!!_this.searchQuarterData) {
							if (_this.$refs.bar1_1) {
								_this.$refs.bar1_1.initData(
									[
										{name: '冷链物流总额', type: 'bar', color: 'blue', data: _bar1data_1.bar, dw: '万亿元'},
									],
									_bar1data_1.xaxis
								);
							}
						}
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷链物流总额', type: 'bar', color: 'green', data: _bar1data.bar, dw: '万亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bar1data.line, dw: '%'},
								],
								_bar1data.xaxis
							);
						}
					},300)
				}
			})
		},
	}
}
</script>
