<template>
    <lee-page code="yanjiu" bg-img="/images/bg_head_1.jpg" title-class="margin-top-100" height="280" abs-title="细分领域报告" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'细分领域报告'}]">
        <div class="bg-img-2"  style="background-color:#f8f9fa">
            <div class="wrap1100 padding-top-100 padding-bottom-100">
              <div class="cl"></div>
              <template v-if="list.length > 0">
								<el-row :gutter="20" class="mg-t-30">
									<el-col :span="6" v-for="(item,index) in list">
										<div class="hide hand rel" style="width:100%;height:206px;background-size:100% 100%;background-repeat:no-repeat;padding:20px;border-radius: 10px;" @click="$router.push({path:'/yanjiu/domain/list/'+item.id})" :style="{backgroundImage:'url('+$utils.formatImagePath(item.head_img)+')'}">
											<div class="f30 dark-0 margin-top-30 f-lq">{{ item.name }}</div>
											<div class="f12 margin-top dark-2 bg-light inline-block" style="padding:5px 10px;border-radius: 5px;">了解更多&gg;</div>
										</div>
									</el-col>
								</el-row>
              </template>
              <el-empty description="暂无数据" v-else></el-empty>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "xifenIndex",
    data(){
      return{
        list:[],
        searchForm:{
          akey:'domainReport',
					pid:'0',
					state:1,
					orderBy:'sort',
					sortBy:'asc'
        },
        totalCount:0
      }
    },
    created() {
        // this.id = this.formData.tid = this.$route.params.id ? this.$route.params.id : '';
    },
    mounted() {
        this.getList();
    },
    methods:{
			toInfo(item){
				let _this = this;
				this.$router.push({path:'/survey/info/'+item.id})
			},
      getList(){
        let _this = this;

        this.$http.post({
          url:'/Type/listAll',
          data:this.searchForm,
          success(res){
            _this.list = res;
          }
        })
      }
    }
}
</script>
