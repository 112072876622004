<template>
	<lee-page code="hydata" bg-img="/images/bg_head_3.jpg" type="light" title-class="margin-top-100" height="250" absTitle="进出口相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'进出口'}]">
		<div class="bg-img-2  padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_inout">
				<div class="wrap1100 padding-bottom-100">
					<el-row :gutter="20">
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'zongzhi' ? 'bg-green light' : 'green'" @click="onChangeDataType('zongzhi')">总值</div>
						</el-col>
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'pinlei' ? 'bg-green light' : 'green'" @click="onChangeDataType('pinlei')">品类</div>
						</el-col>
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'guobie' ? 'bg-green light' : 'green'" @click="onChangeDataType('guobie')">国别</div>
						</el-col>
						<el-col :span="6">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="dataType === 'kouan' ? 'bg-green light' : 'green'" @click="onChangeDataType('kouan')">口岸</div>
						</el-col>
					</el-row>
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<in-out-zongzhi v-if="dataType === 'zongzhi'"></in-out-zongzhi>
						<in-out-pinlei v-if="dataType === 'pinlei'"></in-out-pinlei>
						<in-out-country v-if="dataType === 'guobie'"></in-out-country>
						<in-out-kouan v-if="dataType === 'kouan'"></in-out-kouan>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
import InOutZongzhi from './components/InOutZongzhi'
import InOutPinlei from './components/InOutPinlei'
import InOutCountry from './components/InOutCountry'
import InOutKouan from './components/InOutKouan'
export default {
	name: "List",
	components: {InOutKouan, InOutZongzhi,InOutPinlei,InOutCountry},
	data() {
		return {
			dataType:'zongzhi'
		}
	},
	created() {

	},
	mounted() {
	},
	methods: {
		onChangeDataType(e){
			this.dataType = e;
		},
	}
}
</script>
