<template>
	<lee-user-main-page type="dark" ref="userMain">
		<lee-user-page class="padding-bottom-100">
			<template v-if="company">
				<add-company v-if="type === 'edit'" @success="onSuccess"></add-company>
				<company-info :data="company" @toReSubmit="toReSubmit" v-else></company-info>
				<manage-company v-if="company.user_type === 'creator' && company.is_check === 1"></manage-company>
			</template>
			<div v-else>
				<add-company v-if="type === 'add'" @success="onSuccess"></add-company>
				<join-company v-else-if="type === 'join'" @success="onSuccess"></join-company>
				<template v-else>
					<div style="padding: 40px;">
						<div style="color: #777;font-size:16px;margin-bottom: 20px;">还没有企业信息，您可以选择创建企业或加入企业</div>
						<el-row :gutter="30">
							<el-col :span="12">
								<div style="border:5px solid #7ED321;border-radius: 10px;padding:30px;font-size: 30px;color:#7ED321;" class="hand f-lq text-center" @click="type = 'add'">
									<img src="/images/create_company.png" style="height: 50px;vertical-align: middle;"/>
									创建企业
								</div>
							</el-col>
							<el-col :span="12">
								<div style="border:5px solid #409EFF;border-radius: 10px;padding:30px;font-size: 30px;color:#409EFF;" class="hand f-lq text-center" @click="type = 'join'">
									<img src="/images/join_company.png" style="height: 50px;vertical-align: middle;"/>
									加入企业
								</div>
							</el-col>
						</el-row>
					</div>
				</template>
			</div>
		</lee-user-page>
	</lee-user-main-page>
</template>

<script>
import AddCompany from './addCompany'
import JoinCompany from './joinCompany'
import CompanyInfo from './companyInfo'
import ManageCompany from './manageCompany'
export default {
	name: "index",
	components: {ManageCompany, JoinCompany, AddCompany,CompanyInfo},
	data() {
		return {
			company:null,
			type:''
		}
	},
	created() {
	},
	mounted() {
		this.getMyCompany();
	},
	methods: {
		getMyCompany(){
			let _this = this;
			this.$http.post({
				url: '/UserCompany/myCompany',
				data: null,
				success(res) {
					if(res) {
						res.files_ids = res.files_ids && res.files_ids.length > 0 ? res.files_ids[0] : '';
						_this.company = res;
					}
				}
			});
		},

		onSuccess(){
			console.log('success')
			this.type = '';
			this.$message.success('提交成功');
			this.getMyCompany();
			this.$refs.userMain.refreshHeader();
		},
		toReSubmit(){
			this.type = 'edit';
		},
	}
}
</script>
