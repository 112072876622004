<template>
  <div class="box-shadow bg-light green padding-20 text-center border-radius-5 margin-auto margin-top-30 margin-bottom-30" style="width: 700px;" v-if="check">
    <i class="orange margin-right el-icon-warning"></i>
    {{check.msg}}
    <div class="text-center margin-top-20">
      <login-button v-if="check.type ==='login'"></login-button>
<!--      <el-button size="small" type="warning" class="margin-left" @click="getPayCode" v-if="check.type === 'pay' || check.type === 'pay_count' || check.type === 'pay_vip'">单独购买(￥{{ price }})</el-button>-->
      <el-button size="small" type="danger" class="margin-left" @click="$router.push({path:'/user/vip'})" v-if="check.type === 'count' || check.type === 'pay_count'">升级VIP</el-button>
      <el-button size="small" type="danger" class="margin-left" @click="$router.push({path:'/user/vip'})" v-if="check.type === 'vip' || check.type === 'pay_vip'">成为VIP</el-button>
    </div>
    <el-dialog
      append-to-body
      title="支付"
      :visible.sync="showPay" @close="closePay" v-if="title && check">
      <lee-pay-view :name="title" :price="price" :title="payTitle" :sub-title="paySubTitle" file-size="" :qrcode="'data:image/jpeg;base64,'+qrcode"></lee-pay-view>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "VipCheckButton",
  data() {
    return {
      showPay:false,
      qrcode:'',
      time:null,
      order_id:''
    }
  },
  props:{
    check:{
      type:[Array,Object],
      default(){
        return null
      }
    },
    price:{
      type:[Number,String],
      default:0
    },
    title:{
      type:String,
      default:''
    },
    type:{
      type:String,
      default:''
    },
    datas:{
      type:[Array,Object],
      default(){
        return null
      }
    },
    payTitle:'',
    paySubTitle:''
  },
  created() {
  },
  mounted() {
  },
  methods: {
    getPayCode(){
      let _this = this;
      if(this.datas && this.datas.id && this.type) {
        this.$http.post({
          url: '/Order/getPayCode',
          data: {id: this.datas.id, order_type: this.type},
          success(res) {
            _this.qrcode = res.qrcode;
            _this.order_id = res.order_id;
            _this.showPay = true;
            _this.timeDown('checkState');
          }
        })
      }
    },
    timeDown(type){
      if(type === 'checkState') {
        this.time = setInterval(this.checkState, 3000)
      }
    },
    closePay(){
      this.showPay = false;
      clearInterval(this.time);
      this.time = null;
      this.qrcode = '';
      this.order_id = '';
    },
    checkState(){
      let _this = this;
      this.$http.post({
        url:'/Common/checkOrder',
        data:{id:this.order_id},
        success(res){
          if(res.state === 2){
            clearInterval(_this.time);
            _this.time = null;
            // _this.info.bid_state = 1;
            _this.showPay = false;
            _this.$message.success('支付成功');

            _this.getDetail();
          }
        }
      })
    },
  },
  destroyed() {
    if(this.time) {
      clearInterval(this.time);
      this.time = null;
    }
  }
}
</script>
