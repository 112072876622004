<template>
  <div class="padding-bottom-100" v-if="done">
    <div v-if="type === 'ok'">
      <slot></slot>
    </div>
    <vip-check-button :check="{msg:msg,type:type,data:data}" :price="0" title="" pay-title="购买政策标准详情" pay-sub-title="政策标准详情金额" type="policy" v-else></vip-check-button>
  </div>
</template>

<script>
export default {
  name: "CheckVip",
  data() {
    return {
      state: 0,
      msg: '',
      type: '',
      data: '',
      done: false
    }
  },
  props: {
    akey: {
      type: String,
      default: ''
    }
  },
  created() {
    if (!this.akey) {
      this.type = 'ok'
    }
  },
  mounted() {
    this.check();
  },
  methods: {
    check() {
      let _this = this;

      if (this.akey) {
        this.$http.post({
          url: '/BlockVip/checkBlock',
          data: {akey: this.akey},
          success(res) {
            _this.msg = res.msg;
            _this.type = res.type;
            _this.data = res.data;

            _this.done = true;

            if(res.type === 'ok') {
              _this.$emit('done');
            }
          }
        });
      } else {
        this.type = 'ok';
      }
    }
  }
}
</script>
