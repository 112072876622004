<template>
    <!--    <lee-page bg-img="/images/bg_head_9.jpg" title-class="margin-top-100" :height="280" title="我的提问" :bread="['212','2121']">-->
    <lee-page bg-img="/images/bg_head_9.jpg" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '120' : '280'" :back="$isMobile" :title="$isMobile ? '' : '我的回答'" :bread="[{title:'问答中心',url:'/qa'},{title:'我的回答'}]">
        <div :class="$isMobile ? 'padding-bottom-100 padding-left-right bg-light' : 'bg-img-2  padding-top-50'" style="background:#f8f9fa">
            <div class="cl"></div>
            <div :class="$isMobile ? '' : 'wrap1100'" :style="{marginTop:$isMobile ? '-40px' : ''}">
                <template v-if="list.length > 0">
                    <div class="bg-light border-radius-10 margin-bottom-20 padding-20 box-shadow" v-for="(item,index) in list">
                      <div class="cl">
                        <div class="z f16 dark-3">问题：{{item.title}}</div>
                        <div class="y">
                          <span class="inline-block vm orange margin-right hand" @click="toDel(item.id)">
                            <i class="el-icon-delete"></i>
                            删除
                          </span>
                        </div>
                      </div>
                      <div class="margin-top" v-html="'我的回答：'+item.content">{{item.content}}</div>
                    </div>
                    <div class="text-center margin-top-100">
                        <el-pagination
                            background
                            layout="prev, pager, next, jumper"
                            :page-size="pageSize"
                            :current-page.sync="page"
                            :total="total"
                            @current-change="pageChange">
                        </el-pagination>
                    </div>
                </template>
                <div class="text-center padding-top-bottom-40 f20 dark-3" v-else>
                    暂无数据
                </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            list:[],
            page:1,
            pageSize:15,
            total:0,

            uid:this.$user.getUid()
        }
    },
    created() {
        this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    },
    mounted() {
        this.getList();
    },
    methods:{
      toDel(id){
        let _this = this;
        this.$confirm('是否删除此问题?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post({
            url:'/AuthAnswer/del',
            data:{id:id},
            success(res){
              _this.$message.success('删除成功');
              _this.getList();
            }
          })
        })
      },
        getList(){
            let _this = this;

            this.$http.post({
                url: '/User/getMyAnswer',
                data: {page:this.page,pageSize:this.pageSize},
                success(res) {
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.page = page;
            this.$router.replace({query:{page:this.page}});
        },
    }
}
</script>
