<template>
	<div v-if="data">
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">按销量-地区分析</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20">
			<div class="margin-top-20" v-if="data && data && data.now.content1" v-html="data.now.content1">
				{{data.now.content1 ? data.now.content1 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<el-row>
					<el-col :span="24">
						<lee-bar-echarts-hor
							key="bar2"
							ref="bar2"
							:grid-bottom="10"
							:grid-top="10"
							grid-right="10%"
							height="350px"
							:show-legend="false"
							legend-orient="vertical"
							legend-pos="right"
							:show-left="true"
							:show-right="false"
						></lee-bar-echarts-hor>
					</el-col>
					<el-col :span="24">
						<lee-pie-echarts
							key="pie1"
							ref="pie1"
							:grid-bottom="30"
							height="350px"
							:show-legend="false"
							label-format="{b}:{d}%"
							label-pos="outside"
							:radius="['30%','50%']"></lee-pie-echarts>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CarAreaSale",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCarArea/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCarArea/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		onClickMap(e){
			console.log(e);
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataColdCarArea/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bar2data = {bar: [], bar2: [], xaxis: []};
					let _pie1Data = [];

					_pie1Data.push({value: res.now.huazhong, name: '华中'});
					_pie1Data.push({value: res.now.huadong, name: '华东'});
					_pie1Data.push({value: res.now.huanan, name: '华南'});
					_pie1Data.push({value: res.now.huabei, name: '华北'});
					_pie1Data.push({value: res.now.dongbei, name: '东北'});
					_pie1Data.push({value: res.now.xinan, name: '西南'});
					_pie1Data.push({value: res.now.xibei, name: '西北'});

					_bar2data.bar.push(res.now.huazhong);
					_bar2data.bar.push(res.now.huadong);
					_bar2data.bar.push(res.now.huanan);
					_bar2data.bar.push(res.now.huabei);
					_bar2data.bar.push(res.now.dongbei);
					_bar2data.bar.push(res.now.xinan);
					_bar2data.bar.push(res.now.xibei);

					setTimeout(()=>{
						if (_this.$refs.pie1) {
							_this.$refs.pie1.initData(_pie1Data);
						}

						if (_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: res.now.time_str, type: 'bar', color: 'most', data: _bar2data.bar, dw: '辆'},
									// {name: _pre_area.time_str, type: 'bar', color: 'blue', data: _bar2data.bar2, dw: '辆'},
								],
								['华中', '华东', '华南', '华北', '东北', '西南', '西北']
							);
						}
					},300)
				}
			})
		},
	}
}
</script>
