<template>
    <lee-page code="zhengce" title-class="margin-top-100" bg-img="/images/bg_head_2.jpg" :abs-title="title" sub-title="Policy Standard" height="280" des="" search :back="$isMobile" :bread="[{title:'政策标准',url:'/zhengce'},{title:title}]" stop-search to-path="/zhengce/search" show-year search-time-key="push_time" show-policy>
        <div class="bg-img-2  padding-top-100" :style="{backgroundColor: !$isMobile ? '#f8f9fa' : ''}">
            <div class="wrap1100 padding-bottom-100">
                <div v-if="$isMobile" class="margin-bottom" style="margin-top:-45px;">
                    <el-row :gutter="10">
                        <el-col :span="6" v-for="(item,index) in tabs">
                            <div class="box-shadow bg-light border-radius-5 padding" style="height: 70px;" :style="{backgroundImage:'url('+item.icon+')',backgroundRepeat:'no-repeat',backgroundSize:'45px 45px',backgroundPosition:'45px 45px'}" @click="$router.push({path:item.path})">
                                <div class="f14">{{item.title}}</div>
                                <div class="f-thin f12">{{item.subTitle}}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
<!--              <lee-tabs :list="tabList" :default-index="mm_type" @change="onTabChange" class="margin-auto margin-bottom-50" style="width: 500px;"></lee-tabs>-->
	            <div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{total}}</b> 条相关内容</div>
                <template v-if="list.length > 0">
                    <div class="box-shadow bg-light margin-bottom-20 hand" :class="$isMobile ? 'border-radius-5 hide' : ''" v-for="(item,index) in list" @click="$router.push({path:'/'+type+'/detail/'+item.id})">
                        <img :src="item.head_img ? $config.assetsUrl+item.head_img : '/images/none.jpg'" :width="$isMobile ? '100' : '150'" :height="$isMobile ? '100' : '150'" class="vm" :class="$isMobile ? 'border-radius-5' : ''"/>
                        <div class="hide" :style="{height:$isMobile ? '100px' : '150px',paddingLeft:$isMobile ? '100px' : '150px',marginTop:$isMobile ? '-100px' : '-150px'}">
                            <div class="margin">
                                <div class="cl">
                                    <div class="z green-line padding-right-20" :style="{lineHeight:$isMobile ? '20px' : '30px'}">
                                        <img src="/images/icon/icon_30.png" height="22" v-if="!$isMobile" class="margin-right"/>
                                        <span class="dark-0 vm f-b" :class="$isMobile ? 'f14' : 'f18'">{{item.title}}</span>
                                    </div>
                                    <div class="y green f14" style="line-height: 30px;" v-if="!$isMobile">查看更多</div>
                                </div>
                                <div class="f14 dark-3 text-line-2" v-html="item.content"></div>
															<div class="f14 margin-top" style="color: #ccc;" v-if="item.push_time">{{item.push_time}}</div>
                            </div>
                        </div>
                    </div>
                </template>
                <el-empty description="暂无数据" v-else></el-empty>
                <div class="text-center margin-top-100" v-if="list.length > 0">
                    <el-pagination
                        background
                        layout="pager, jumper"
                        :page-size="pageSize"
                        :current-page.sync="searchForm.page"
                        :total="total"
                        @current-change="pageChange">
                    </el-pagination>
                </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            type:'',
            id:'',
            list:[],
            pageSize:10,
            total:0,
            zcList:[
                {title:'补贴政策',subTitle:'Subsidies',path:'/zhengce/list/policy/1',icon:'/images/icon/icon_49.png'},
                {title:'规划政策',subTitle:'Planning',path:'/zhengce/list/policy/2',icon:'/images/icon/icon_50.png'},
                {title:'征询政策',subTitle:'Consult',path:'/zhengce/list/policy/4',icon:'/images/icon/icon_51.png'},
                {title:'管控政策',subTitle:'Controls',path:'/zhengce/list/policy/3',icon:'/images/icon/icon_52.png'}
            ],
            bzList:[
                {title:'参考标准',subTitle:'Consult',path:'/zhengce/list/standard/1',icon:'/images/icon/icon_49.png'},
                {title:'团体标准',subTitle:'Team',path:'/zhengce/list/standard/3',icon:'/images/icon/icon_50.png'},
                {title:'强制标准',subTitle:'Force',path:'/zhengce/list/standard/4',icon:'/images/icon/icon_51.png'},
                {title:'行业标准',subTitle:'Industry',path:'/zhengce/list/standard/2',icon:'/images/icon/icon_52.png'}
            ],
            tabs:[],
            searchForm:{
                page:1,
                title:''
            },
            title:'',
          mm_type:'chain',
          tabList:[
            {label:'冷链',value:'chain',icon:'/images/icon/icon_57_'},
            {label:'农产品',value:'agriculture',icon:'/images/icon/icon_58_'},
          ],
        }
    },
    created() {
        if(!this.$utils.isEmptyObject(this.$route.query)){
            this.searchForm = {...this.$route.query};
        }

        this.type = this.$route.params.type;
        this.id = this.$route.params.id;

        this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';

        let _search = ['cankao','hangye','team','qiangzhi'];

        if(_search.indexOf(this.type) >= 0){
            this.tabs = this.bzList;
        }else{
            this.tabs = this.zcList;
        }

        // if(this.type === 'policy'){
        //     if(this.id === '446826541721059328'){
        //         this.title = '补贴政策';
        //     }else if(this.id === '446577251438297088'){
        //         this.title = '规划政策';
        //     }else if(this.id === '446576845656162304'){
        //         this.title = '管控政策';
        //     }else if(this.id === '449312100309794816'){
        //         this.title = '征询政策';
        //     }
        // }else if(this.type === 'standard'){
        //     if(this.id === '449325866715971584'){
        //         this.title = '参考标准';
        //     }else if(this.id === '449325956146921472'){
        //         this.title = '行业标准';
        //     }else if(this.id === '449326117623431168'){
        //         this.title = '团体标准';
        //     }else if(this.id === '446596069850087424'){
        //         this.title = '强制标准';
        //     }
        // }
    },
    mounted() {
        this.getList();
        this.getTypeDetail();
    },
    methods:{
      // onTabChange(i){
      //   this.mm_type = i.value;
      //   this.getList();
      // },
        getList(){
            let _this = this;

            if(this.id) {
                this.$http.post({
                    url: '/Policy/listPage',
                    data: {...this.searchForm,type_policy_id: this.id,pageSize:this.pageSize,orderBy:'push_time',sortBy:'desc'},
                    success(res) {
                        _this.list = res.list;
                        _this.total = res.totalCount;
                    }
                });
            }
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm}});
        },
        getTypeDetail(){
            let _this = this;

            if(this.id) {
                this.$http.post({
                    url: '/Type/detail',
                    data: {id:this.id},
                    success(res) {
                        // console.log(res);
                        _this.title = res.name;
                    }
                });
            }
        }
    }
}
</script>
