<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">在建项目相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="text-center margin-top-20">
			<color-china-echarts :selected="mapSelectedName" ref="china" height="600px" @click="onClickMap" @onload="onClickMap" dw="家"></color-china-echarts>
		</div>
		<div class="margin-top-20">
			<table class="lee-table margin-top-30 margin-auto" style="width: 100%;">
				<tr class="title">
					<td width="200">项目名称</td>
					<td width="80">月份</td>
					<td>项目介绍</td>
					<td width="100">所属省份</td>
					<td width="80">状态</td>
					<td width="100">金额(亿元)</td>
				</tr>
				<template v-if="projectList.length > 0">
					<tr v-for="(t,i) in projectList">
						<td>{{t.name}}</td>
						<td>{{t.month}}月</td>
						<td style="text-align: left;padding: 10px;">{{t.content}}</td>
						<td>{{t.province_name}}</td>
						<td>{{t.status_name}}</td>
						<td>{{t.price}}</td>
					</tr>
				</template>
				<template v-else>
					<tr>
						<td colspan="6">
							<div class="text-center f16" style="padding:40px 0">暂无数据</div>
						</td>
					</tr>
				</template>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: "LengkuProject",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:'',

			vacancySelect:'beijing',
			priceSelect:'beijing',
			provinceSelect:'beijing',
			mapSelectedName:'',
			projectList:[]
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onClickMap(e){
			console.log(e);
			let _this = this;
			this.$http.post({
				url: '/HydataLengkuProjectItem/listAll',
				data: {tid:this.data.now.id,province_code:e.province_code},
				success(res) {
					_this.projectList = res;
				}
			});
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuProject/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuProject/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLengkuProject/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _chinaData = [];
					const maxValue = Math.max(...res.area.map(obj => obj.count));
					const maxElement = res.area.find(obj => obj.count === maxValue);
					_this.mapSelectedName = maxElement.name;

					for(let i=0;i< res.area.length;i++){
						_chinaData.push({name:res.area[i].name,value:parseInt(res.area[i].count)});
					}

					setTimeout(()=>{
						if(_this.$refs.china){
							_this.$refs.china.initData(_chinaData);
						}
					},200)
				}
			})
		},
	}
}
</script>
