<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">冷库总量相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1 ? data.now.content1 : ''">
			{{data.now.content1 ? data.now.content1 : ''}}
		</div>
		<div class="text-center margin-top-20">
			<lee-bar-echarts
				key="bar1"
				ref="bar1"
				:grid-bottom="30"
				:grid-top="30"
				left-name="（万吨）"
				height="350px"
				show-legend
			></lee-bar-echarts>
		</div>
		<div class="text-center margin-top-20">
			<lee-bar-echarts
				key="bar2"
				ref="bar2"
				:grid-bottom="30"
				:grid-top="30"
				left-name="（亿立方米）"
				height="350px"
				show-legend
			></lee-bar-echarts>
		</div>
		<template  v-if="!searchQuarterData || searchQuarterData === '0'">
			<div class="margin-top-20">
				<div style="border-bottom: 2px solid #7ED321;">
					<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按地区分布</span>
				</div>
				<div class="margin-top-20" v-if="data && data.now && data.now.content2" v-html="data.now.content2 ? data.now.content2 : ''">
					{{data.now.content2 ? data.now.content2 : ''}}
				</div>
				<div class="text-center margin-top-20">
					<el-row>
						<el-col :span="12">
							<lee-bar-echarts-hor
								key="bar3"
								ref="bar3"
								:grid-bottom="10"
								:grid-top="10"
								grid-right="10%"
								height="350px"
								:show-legend="false"
								legend-orient="vertical"
								legend-pos="right"
								:show-left="true"
								:show-right="false"
							></lee-bar-echarts-hor>
						</el-col>
						<el-col :span="12">
							<lee-pie-echarts
								key="pie3_3"
								ref="pie3_3"
								:grid-bottom="30"
								height="350px"
								:show-legend="false"
								label-format="{b}:{d}%"
								label-pos="outside"
								:radius="['30%','50%']"></lee-pie-echarts>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="margin-top-20">
				<div style="border-bottom: 2px solid #7ED321;">
					<span class="light bg-green" style="padding:7px 20px;display: inline-block;">按省份分布</span>
				</div>
				<div class="margin-top-20" v-if="data && data.now && data.now.content3" v-html="data.now.content3 ? data.now.content3 : ''">
					{{data.now.content3 ? data.now.content3 : ''}}
				</div>
				<div>
					<color-china-echarts dw="万吨" ref="china" height="600px" @click="onClickMap" @onload="onClickMap"></color-china-echarts>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name: "Lengku",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onClickMap(e){
			console.log(e);
		},
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengku/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengku/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLengku/getData',
				data: _where,
				success(res) {
					_this.data = res;
					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata2 = {bar1: [], line1: [], xaxis: []};
					let _bardata3 = {bar1: [], xaxis: []};
					let _bardata4 = {bar1: [], xaxis: []};
					let _pie3_3Data = [];
					let _chinaData = [];

					for (let i = 0; i < res.list.length; i++) {
						_bardata1.bar1.push(res.list[i].total);
						_bardata1.line1.push(res.list[i].total_rate);
						_bardata1.xaxis.push(res.list[i].time_str);
						_bardata2.bar1.push(res.list[i].amount);
						_bardata2.line1.push(res.list[i].amount_rate);
						_bardata2.xaxis.push(res.list[i].time_str);
					}

					for (let i = 0; i < res.area.length; i++) {
						_bardata3.bar1.push(res.area[i].value);
						_bardata3.xaxis.push(res.area[i].name);

						_pie3_3Data.push({value:parseFloat(res.area[i].value),name:res.area[i].name});
					}

					for (let i = 0; i < res.province_list.length; i++) {
						_bardata4.bar1.push(parseInt(res.province_list[i].value));
						_bardata4.xaxis.push(res.province_list[i].name);
						_chinaData.push({name:res.province_list[i].name,value:parseInt(res.province_list[i].value)});
					}

					setTimeout(()=>{
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷库总库容', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '万吨'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '冷库总容积', type: 'bar', color: 'blue', data: _bardata2.bar1, dw: '亿立方米'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata2.line1, dw: '%'},
								],
								_bardata2.xaxis
							);
						}

						if(_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '冷库库容', type: 'bar', color: 'most', data: _bardata3.bar1, dw: '万吨'},
								],
								_bardata3.xaxis
							);
						}

						if(_this.$refs.pie3_3) {
							_this.$refs.pie3_3.initData(_pie3_3Data);
						}

						if(_this.$refs.bar4) {
							_this.$refs.bar4.initData(
								[
									{name: '冷库库容', type: 'bar', color: 'blue', data: _bardata4.bar1, dw: '万吨'},
								],
								_bardata4.xaxis
							);
						}
						if(_this.$refs.china){
							_this.$refs.china.initData(_chinaData);
						}
					},200)
				}
			})
		},
	}
}
</script>
