<template>
	<lee-page bg-img="/images/bg_head_9.jpg" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '120' : '280'" :back="$isMobile" :title="$isMobile ? '' : info.title" :bread="[{title:'企业数据库',url:'/company'},{title:info.title}]" v-if="info">
		<div :class="!$isMobile ? 'bg-img-2  padding-top-100' : 'padding-left-right'" :style="{backgroundColor: !$isMobile ? '#f8f9fa' : '',marginTop:$isMobile ? '-40px' : ''}">
			<div :class="$isMobile ? '' : 'wrap1100'">
				<div class="box-shadow bg-light" :class="$isMobile ? 'padding border-radius-5' : 'padding-30'" v-if="info">
					<div class="green-line">
						<div class="cl f34" style="line-height: 30px;">
<!--							<span class="z line-index bg-green light f16">{{ info.arank }}</span>-->
							<div class="z margin-left" :class="$isMobile ? 'f18' : 'f22'">{{ info.title }}</div>
<!--							<lee-updown-tag class="y" :type="info.arank_change > 0 ? 'up' : 'down'" :number="info.arank_change" :isnew="info.arank_change === 0" v-if="!$isMobile"></lee-updown-tag>-->
						</div>
					</div>
					<div class="f16 dark-1">
<!--						<img src="/images/banner_1.jpg" class="content-img" style="height: 100px;width: 100%;"/>-->
						<template v-if="$user.getToken()">
							<div class="margin-top">
								<el-row :gutter="10">
									<el-form :model="info" class="big-form" label-width="140px">
										<el-col :span="8">
											<el-form-item label="企业名称：">
												{{info.title}}
											</el-form-item>
										</el-col>
										<el-col :span="16">
											<el-form-item label="关键词：">
												<el-tag :key="tag" v-for="tag in info.tags" :closable="false" :disable-transitions="false" style="margin-right: 10px;">{{tag}}</el-tag>
											</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="企业法人：">
												{{info.faren}}
											</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="企业所在地：">
												{{info.province_name}} - {{info.city_name}}
											</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="企业性质：">{{info.nature.name}}</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="企业属性：">{{info.property.name}}</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="主要网点：">
												<el-tag class="margin-right" v-for="(t,i) in info.main_location">{{t}}</el-tag>
											</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="合作客户：">
												<el-tag class="margin-right" v-for="(t,i) in info.customer">{{t}}</el-tag>
											</el-form-item>
										</el-col>
										<el-col :span="8">
											<el-form-item label="荣誉：">
												<el-tag class="margin-right" v-for="(t,i) in info.honor">{{t}}</el-tag>
											</el-form-item>
										</el-col>
										<el-col :span="16">
											<el-form-item label="企业宣传PPT：">
												<div>
													<div v-for="(t,i) in info.files">
														<a :href="$utils.formatImagePath(t.url)" target="_blank" style="padding:5px 10px;border-radius: 5px;" class="bg-brand-0 light">{{t.name}}</a>
													</div>
												</div>
											</el-form-item>
										</el-col>
									</el-form>
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">所在地区</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<div style="height: 40px;line-height: 40px;">{{ info.province_name }} - {{info.city_name}}</div>-->
<!--									</el-col>-->
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">企业性质</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<div style="height: 40px;line-height: 40px;">{{ info.nature.name }}</div>-->
<!--									</el-col>-->
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">企业属性</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<div style="height: 40px;line-height: 40px;">{{ info.property.name }}</div>-->
<!--									</el-col>-->
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">主要网点</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<el-tag v-for="t in info.main_location">{{t}}</el-tag>-->
<!--									</el-col>-->
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">合作客户</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<div style="height: 40px;line-height: 40px;">{{ info.customer }}</div>-->
<!--									</el-col>-->
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">荣誉</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<div style="height: 40px;line-height: 40px;">{{ info.honor }}</div>-->
<!--									</el-col>-->
<!--									<el-col :md="3" :xs="8" class="violet f16">-->
<!--										<div style="height: 40px;line-height: 40px;">企业宣传PPT</div>-->
<!--									</el-col>-->
<!--									<el-col :md="9" :xs="16" class="f-thin f16">-->
<!--										<div style="padding-top:10px;">-->
<!--											<div class="margin-bottom" v-for="(t,i) in info.files">-->
<!--												<a :href="$utils.formatImagePath(t.url)" class="under" target="_blank">{{t.name}}</a>-->
<!--											</div>-->
<!--										</div>-->
<!--									</el-col>-->
								</el-row>
							</div>
							<div class="margin-top-30">
								<div style="border-bottom: 1px solid #ddd;padding-bottom:10px;" class="cl">
									<div class="z">相关资讯</div>
									<div class="y text-center dark-3">共查询到 <b class="red">{{ totalCount }}</b> 条相关内容</div>
								</div>
								<div class="margin-top-20" v-if="list.length > 0">
									<div class="box-shadow bg-light margin-bottom-20 hand" v-for="(item,index) in list" @click="$router.push({path:'/news/detail/'+item.id})">
										<div class="hide">
											<div class="margin">
												<div class="cl">
													<div class="z green-line padding-right-20" style="line-height: 30px;">
														<img src="/images/icon/icon_30.png" height="22" class="margin-right"/>
														<span class="dark-0 vm f18" v-html="item.title"></span>
													</div>
												</div>
												<div class="f14 dark-3 text-line-2" v-html="item.content"></div>
											</div>
										</div>
									</div>
									<div class="text-center margin-top-10">
										<el-pagination
											background
											layout="prev,pager,next"
											:page-size="searchForm.pageSize"
											:current-page.sync="searchForm.page"
											:total="totalCount"
											@current-change="pageChange">
										</el-pagination>
									</div>
								</div>
								<el-empty description="未查询到相关内容" v-else></el-empty>
							</div>
						</template>
						<div class="box-shadow bg-light green padding-20 text-center border-radius-5 margin-auto margin-top-70 margin-bottom-30" style="width: 500px;" v-else>
							<i class="orange margin-right el-icon-warning"></i>你无权查看该信息，请登录
							<login-button></login-button>
						</div>
					</div>
					<lee-good-view class="margin-top-50" :id="info.id" akey="company"></lee-good-view>
					<lee-comment-view :id="info.id" akey="company"></lee-comment-view>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			textarea: '',
			id: '',
			info: null,
			menkan: 0,
			list: [],
			searchForm:{
				companyid:'',
				page:1,
				pageSize:10
			},
			totalCount:0
		}
	},
	created() {
		this.id = this.$route.params.id;
		this.searchForm.companyid = this.$route.params.id;
	},
	mounted() {
		this.getDetail();
		this.getNews();
		// this.getMenKan();
		// this.getData();
	},
	methods: {
		pageChange(e){
			this.searchForm.page = e;
			this.getNews();
		},
		getDetail() {
			let _this = this;
			this.$http.post({
				url: '/Company/detail',
				data: {id: this.id},
				success(res) {
					_this.info = res;

					// _this.getMenKan(res.year);
				}
			});
		},
		getNews() {
			let _this = this;
			this.$http.post({
				url: '/News/listPage',
				data: this.searchForm,
				success(res) {
					_this.list = res.list;
					_this.totalCount = res.totalCount;

					// _this.getMenKan(res.year);
				}
			});
		},
		// getMenKan(year) {
		// 	let _this = this;
		// 	this.$http.post({
		// 		url: '/BaiqiangTotal/detail',
		// 		data: {year: year},
		// 		success(res) {
		// 			_this.menkan = res.menkan;
		// 		}
		// 	});
		// },
		// getData() {
		// 	let _this = this;
		// 	this.$http.post({
		// 		url: '/Baiqiang/getAllYear',
		// 		data: {id: this.id},
		// 		success(res) {
		// 			_this.list = res;
		//
		// 			let _bar = {bar1: [], bar2: [], bar3: [], bar4: [], xaxis: []};
		//
		// 			console.log(res);
		//
		// 			for (let i = 0; i < res.length; i++) {
		//
		// 				_bar.bar1.push(res[i].amount);
		//
		// 				let _lengku = res[i].lengku_rl ? parseFloat(res[i].lengku_rl) : 0;
		//
		// 				// _lengku += parseFloat(res[i].changwenku);
		// 				// _lengku += parseFloat(res[i].chaodiwenku);
		// 				// _lengku += parseFloat(res[i].lengcangku);
		// 				// _lengku += parseFloat(res[i].lengdongku);
		//
		// 				let _car = 0;
		// 				_car += res[i].heavy_car;
		// 				_car += res[i].light_car;
		// 				_car += res[i].mini_car;
		// 				_car += res[i].out_car;
		// 				_car += res[i].small_car;
		// 				_car += res[i].owner_car;
		//
		// 				_bar.bar2.push(_lengku);
		// 				_bar.bar3.push(_car);
		// 				_bar.bar4.push(res[i].person_count);
		// 				_bar.xaxis.push(res[i].year.toString());
		// 			}
		//
		// 			if (_this.$refs.bar1) {
		// 				_this.$refs.bar1.initData(
		// 					[
		// 						// {name: '营业收入', type: 'bar', color: 'green', data: _bar.bar1,dw:'万元'},
		// 						{name: '冷库总容积', type: 'bar', color: 'blue', data: _bar.bar2, dw: '立方米'},
		// 						{name: '冷藏车总量', type: 'bar', color: 'orange', data: _bar.bar3, dw: '辆'},
		// 						{name: '企业人数', type: 'bar', color: 'red', data: _bar.bar4, dw: '人'},
		// 					],
		// 					_bar.xaxis
		// 				);
		// 			}
		// 		}
		// 	});
		// },
	}
}
</script>
