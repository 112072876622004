<template>
	<lee-page code="hydata" bg-img="/images/bg_head_3.jpg" type="light" title-class="margin-top-100" height="250" abs-title="冷藏车相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷藏车'}]">
		<div class="bg-img-2  padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_car">
				<div class="wrap1100 padding-bottom-100">
					<div class="flex">
						<div class="flex1" v-for="(t,i) in [{n:'保有量',i:'byl'},{n:'销量',i:'sale'},{n:'按地区销量',i:'areaSale'},{n:'按车型销量',i:'typeSale'},{n:'按燃料销量',i:'oilSale'},{n:'按省份销量',i:'provinceSale'},{n:'按厂商销量',i:'supplierSale'},{n:'按新能源厂商销量',i:'supplierXnySale'}]">
							<div class="hand box-shadow-green text-center" style="border-radius: 6px;height: 40px;line-height: 40px;overflow: hidden;" :class="[dataType === t.i ? 'bg-green light' : 'green',i < 7 ? 'margin-right':'']" @click="onChangeDataType(t.i)">{{t.n}}</div>
						</div>
					</div>
					<div class="box-shadow padding-30 bg-light margin-top-30">
						<car-byl v-if="dataType === 'byl'"></car-byl>
						<car-sale v-if="dataType === 'sale'"></car-sale>
						<car-area-sale v-if="dataType === 'areaSale'"></car-area-sale>
						<car-type-sale v-if="dataType === 'typeSale'"></car-type-sale>
						<car-oil-sale v-if="dataType === 'oilSale'"></car-oil-sale>
						<car-province-sale v-if="dataType === 'provinceSale'"></car-province-sale>
						<car-supplier-sale v-if="dataType === 'supplierSale'"></car-supplier-sale>
						<car-supplier-xny-sale v-if="dataType === 'supplierXnySale'"></car-supplier-xny-sale>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
import CarByl from './components/CarByl'
import CarSale from './components/CarSale'
import CarAreaSale from './components/CarAreaSale'
import CarTypeSale from './components/CarTypeSale'
import CarOilSale from './components/CarOilSale'
import CarProvinceSale from './components/CarProvinceSale'
import CarSupplierSale from './components/CarSupplierSale'
import CarSupplierXnySale from './components/CarSupplierXnySale'
export default {
	name: "List",
	components: {CarSupplierXnySale, CarSupplierSale, CarProvinceSale, CarOilSale, CarTypeSale, CarAreaSale, CarSale, CarByl},
	data() {
		return {
			dataType:'byl',
		}
	},
	created() {
		let _this = this;
	},
	mounted() {
	},
	methods: {
		onChangeDataType(e){
			this.dataType = e;
		},
	}
}
</script>
