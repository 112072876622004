<template>
	<div v-if="data">
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">按销量-车型分析</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20">
			<div class="margin-top-20" v-if="data && data && data.now.content1" v-html="data.now.content1">
				{{data.now.content1 ? data.now.content1 : ''}}
			</div>
			<div class="text-center margin-top-20">
				<el-row>
					<el-col :span="24">
						<lee-bar-echarts-hor
							key="bar3"
							ref="bar3"
							:grid-bottom="10"
							:grid-top="10"
							grid-right="10%"
							height="350px"
							:show-legend="false"
							legend-orient="vertical"
							legend-pos="right"
							:show-left="true"
							:show-right="false"
						></lee-bar-echarts-hor>
					</el-col>
					<el-col :span="24">
						<lee-pie-echarts
							key="pie3_3"
							ref="pie3_3"
							:grid-bottom="30"
							height="350px"
							:show-legend="false"
							label-format="{b}:{d}%"
							label-pos="outside"
							:radius="['30%','50%']"></lee-pie-echarts>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CarTypeSale",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCarType/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataColdCarType/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		onClickMap(e){
			console.log(e);
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataColdCarType/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata3 = {bar1: [], xaxis: ['MPV专用','皮卡','轻卡','轻客','微卡','微客','中卡','重卡']};
					let _pie3_3Data = [];
					if(res.now) {
						_bardata3.bar1.push(res.now ? res.now.mpv : 0);
						_bardata3.bar1.push(res.now ? res.now.pika : 0);
						_bardata3.bar1.push(res.now ? res.now.qingka : 0);
						_bardata3.bar1.push(res.now ? res.now.qingke : 0);
						_bardata3.bar1.push(res.now ? res.now.weika : 0);
						_bardata3.bar1.push(res.now ? res.now.weike : 0);
						_bardata3.bar1.push(res.now ? res.now.middle : 0);
						_bardata3.bar1.push(res.now ? res.now.heavy : 0);

						_pie3_3Data.push({value: parseInt(res.now ? res.now.mpv : 0), name: 'MPV专用'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.pika : 0), name: '皮卡'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.qingka : 0), name: '轻卡'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.qingke : 0), name: '轻客'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.weika : 0), name: '微卡'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.weike : 0), name: '微客'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.middle : 0), name: '中卡'});
						_pie3_3Data.push({value: parseInt(res.now ? res.now.heavy : 0), name: '重卡'});
					}

					setTimeout(()=>{
						if (_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '车型数量', type: 'bar', color: 'most', data: _bardata3.bar1, dw: '辆'},
								],
								_bardata3.xaxis
							);
						}
						if(_this.$refs.pie3_3) {
							_this.$refs.pie3_3.initData(_pie3_3Data);
						}
					},300)
				}
			})
		},
	}
}
</script>
