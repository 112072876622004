<template>
	<lee-page code="news" bg-img="/images/bg_head_5.jpg" title-class="margin-top-100" height="280" :title="title" :bread="[{title:'资讯',url:'/news'},{title:data.typeNewsName,url:'/news/list/'+data.type_news_id},{title:data.title}]" v-if="data">
		<div class="bg-img-2  padding-top-50" :style="{backgroundColor: '#f8f9fa'}">
			<div class="wrap1100 padding-bottom-100">
				<div class="box-shadow bg-light padding-30">
					<div class="green-line">
						<div class="cl f34">
							<img src="/images/icon/icon_30.png" height="22" class="no-v-mid"/>
							{{ data.title }}
						</div>
						<div class="margin-top">
							<el-row :gutter="10">
								<el-col :span="8" class="dark-2">发布日期：{{data.create_time}}</el-col>
								<el-col :span="8" v-if="data.source" class="dark-2">来源：{{data.source}}</el-col>
							</el-row>
						</div>
					</div>
					<template v-if="data.check.type === 'ok'">
						<div id="echarts" style="height: 300px;width: 100%;" v-if="data.echarts"></div>
						<div class="f16 dark-1 base-content" v-html="data.html_content ? data.html_content : ''"></div>
						<div class="margin-top-20" v-if="data.files && data.files.length > 0">
							<lee-vip-file-down :url="item.url" :filename="item.name ? item.name : '文件'" v-for="(item,index) in data.files" class="margin-right margin-bottom" akey="standard" :tid="data.id"></lee-vip-file-down>
						</div>
						<lee-good-view class="margin-top-50" :id="data.id" akey="news" :open-like="data.open_like === 1" :open-favorite="data.open_favorite === 1" :open-comment="data.open_comment === 1"></lee-good-view>
					</template>
					<vip-check-button :datas="data" :check="data.check" :price="data.price" :title="data.title" pay-title="购买资讯详情" pay-sub-title="资讯详情金额" type="news" v-else></vip-check-button>
					<!--                  <lee-comment-view total-count="30" akey="news" :id="data.id" v-if="data.open_comment === 1"></lee-comment-view>-->
				</div>
				<template v-if="data.check.type === 'ok'">
					<div class="box-shadow padding-30 bg-light margin-top-30" v-if="data.open_comment === 1">
						<lee-comment-view total-count="30" akey="news" :id="data.id"></lee-comment-view>
					</div>
				</template>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		return {
			title: '',
			id: '',
			data: null,
			echarts:null
		}
	},
	created() {
		this.id = this.$route.params.id;
	},
	mounted() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			let _this = this;

			if (this.id) {
				this.$http.post({
					url: '/News/detail',
					data: {id: this.id},
					success(res) {
						res.echarts = res.echarts ? JSON.parse(res.echarts) : null;
						_this.data = res;
						_this.title = res.title;

						if(res.echarts){
							setTimeout(()=>{
								let _option = JSON.parse(JSON.stringify(res.echarts.result.echarts));
								// _option.grid.top = '10%';
								// _option.grid.bottom = '20%';
								// _option.grid.left = '4%';
								// _option.grid.right = '10%';
								console.log(JSON.stringify(_option));
								_this.echarts = _this.$echarts.init(document.getElementById('echarts'));
								_this.echarts.setOption(_option, true);
							},200)
						}
					}
				});
			}
		}
	}
}
</script>
