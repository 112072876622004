<template>
	<div class="margin-top-20">
		<div>
			<el-button size="small" type="success" @click="toAddCode" class="box-shadow-green">添加邀请码</el-button>
			<span class="f14 margin-left dark-3">还可以创建 <b class="green">{{maxCount - nowCount}}</b> / {{maxCount}} 个</span>
		</div>
		<el-table :data="list">
			<el-table-column prop="code" label="邀请码"></el-table-column>
			<el-table-column prop="uid" label="使用人">
				<template slot-scope="scope">
					{{scope.row.user && scope.row.user.username ? scope.row.user.username : '-'}}
				</template>
			</el-table-column>
			<el-table-column prop="is_used" label="状态">
				<template slot-scope="scope">
					<el-tag type="success" effect="dark" v-if="scope.row.is_used === 1">已使用</el-tag>
					<el-tag type="info" effect="dark" v-else>未使用</el-tag>
				</template>
			</el-table-column>
			<el-table-column prop="use_time" label="使用时间">
				<template slot-scope="scope">
					{{scope.row.use_time ? $moment.unix(scope.row.use_time).format('YYYY-MM-DD HH:mm:ss') : '-'}}
				</template>
			</el-table-column>
			<el-table-column prop="code" label="操作">
				<template slot-scope="scope">
					<el-popconfirm title="确定删除吗？" @confirm="toDel(scope.row)">
						<el-link slot="reference" type="danger">删除</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default {
	name: "manageCompany",
	data() {
		return {
			list:[],
			maxCount:0,
			nowCount:0
		}
	},
	created() {
	},
	mounted() {
		this.getMyCompany();
		this.getCodeList();
	},
	methods: {
		getCodeList(){
			let _this = this;
			this.$http.post({
				url: '/UserCompanyCodeLog/list',
				data: null,
				success(res) {
					_this.list = res;
				}
			});
		},
		getMyCompany(){
			let _this = this;
			this.$http.post({
				url: '/UserCompany/myCompany',
				data: null,
				success(res) {
					_this.maxCount = res.max_count;
					_this.nowCount = res.now_count;
				}
			});
		},
		toDel(row){
			let _this = this;
			if(row.uid){
				this.$confirm('删除该邀请码，后同时也会删除该成员的绑定关系，是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.toDoDel(row.id);
				}).catch(() => {});
			}else {
				this.toDoDel(row.id);
			}
		},
		toDoDel(id){
			let _this = this;
			this.$http.post({
				url: '/UserCompanyCodeLog/del',
				data: {id: id},
				success(res) {
					_this.$message.success('操作成功');
					_this.getCodeList();
					_this.getMyCompany();
				}
			});
		},
		toAddCode(){
			let _this = this;
			if(this.nowCount >= this.maxCount){
				this.$message.error('邀请码数量已达上限，无法添加')
				return;
			}
			this.$confirm('是否要添加邀请码?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post({
					url: '/UserCompanyCodeLog/autoAdd',
					data: null,
					success(res) {
						_this.$message.success('操作成功');
						_this.getCodeList();
						_this.getMyCompany();
					}
				});
			}).catch(() => {});
		}
	}
}
</script>
