<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">冷库市场相关数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
<!--					<el-select class="margin-left" v-model="provinceSelect" @change="onChangeProvince">-->
<!--						<el-option :value="i" :label="t" v-for="(t,i) in $utils.getMarketCityList()"></el-option>-->
<!--					</el-select>-->
				</div>
			</div>
		</div>
<!--		<div class="margin-top-20">-->
<!--			<div style="border-bottom: 2px solid #7ED321;">-->
<!--				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">全国主要城市冷库空置率</span>-->
<!--			</div>-->
<!--			<div class="text-center margin-top-20">-->
<!--				<lee-bar-echarts-->
<!--					key="bar1"-->
<!--					ref="bar1"-->
<!--					:grid-bottom="30"-->
<!--					height="350px"-->
<!--					bar-dw="%"-->
<!--					show-legend-->
<!--				></lee-bar-echarts>-->
<!--			</div>-->
<!--		</div>-->
		<div class="margin-top-20">
			<div class="flex flex-jc-between flex-ai" style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">{{$utils.getMarketCityName(vacancySelect)}}空置率</span>
				<el-select v-model="vacancySelect" size="small" @change="onChangeVacancyCity">
					<el-option :value="i" :label="t" v-for="(t,i) in $utils.getMarketCityList()"></el-option>
				</el-select>
			</div>
			<template v-if="data && data.vacancy && data.vacancy.now">
				<div class="margin-top-20" v-if="data && data.vacancy && data.vacancy.now.content1" v-html="data.vacancy.now.content1 ? data.vacancy.now.content1 : ''">
					{{ data.vacancy.now.content1 ? data.vacancy.now.content1 : '' }}
				</div>
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar2"
						ref="bar2"
						:grid-bottom="30"
						:grid-top="30"
						left-name="空置率(%)"
						height="350px"
						bar-dw="%"
						show-legend
					></lee-bar-echarts>
				</div>
			</template>
			<div class="text-center padding-top-bottom-40 dark-3" v-else>暂无数据</div>
		</div>
		<div class="margin-top-20">
			<div class="flex flex-jc-between flex-ai" style="border-bottom: 2px solid #7ED321;">
				<span class="light bg-green" style="padding:7px 20px;display: inline-block;">{{$utils.getMarketPriceCityName(priceSelect)}}价格</span>
				<el-select v-model="priceSelect" size="small" @change="onChangePriceCity">
					<el-option :value="i" :label="t" v-for="(t,i) in $utils.getMarketPriceCityList()"></el-option>
				</el-select>
			</div>
			<template v-if="data && data.price && data.price.now">
				<div class="margin-top-20" v-if="data.price.now.content1" v-html="data.price.now.content1 ? data.price.now.content1 : ''">
					{{data.price.now.content1 ? data.price.now.content1 : ''}}
				</div>
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar3"
						ref="bar3"
						:grid-bottom="30"
						:grid-top="30"
						left-name="(元/㎡/天)"
						height="350px"
						show-legend
					></lee-bar-echarts>
				</div>
			</template>
			<div class="text-center padding-top-bottom-40 dark-3" v-else>暂无数据</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Market",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:'',

			vacancySelect:'beijing',
			priceSelect:'beijing',
			provinceSelect:'beijing'
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeProvince(e){
			this.onChangeVacancyCity();
			this.onChangePriceCity();
		},
		onChangePriceCity(e){
			let _bardata = {bar1: [],bar2: [],line1:[], xaxis: []};
			if(this.data.price && this.data.price.list) {
				for(let i =0;i < this.data.price.list.length;i++){
					let _data = this.data.price.list[i];
					let _min = _data[this.priceSelect+'_min'];
					let _max = _data[this.priceSelect+'_max'];
					_bardata.bar1.push(parseFloat(parseFloat(_min).toFixed(1)));
					_bardata.bar2.push(parseFloat(parseFloat(_max).toFixed(1)));
					_bardata.xaxis.push(_data.time_str);
				}
			}
			setTimeout(()=>{
				if (this.$refs.bar3) {
					this.$refs.bar3.initData(
						[
							{name: '最低价', type: 'bar', color: 'green', data: _bardata.bar1, dw: '元/㎡/天'},
							{name: '最高价', type: 'bar', color: 'blue', data: _bardata.bar2, dw: '元/㎡/天'},
						],
						_bardata.xaxis
					);
				}
			},200)
		},
		onChangeVacancyCity(e){
			let _bardata = {bar1: [],line1:[], xaxis: []};
			if(this.data.vacancy && this.data.vacancy.list) {
				for(let i =0;i < this.data.vacancy.list.length;i++){
					let _data = this.data.vacancy.list[i];
					_bardata.bar1.push(parseFloat(_data[this.vacancySelect]));
					_bardata.xaxis.push(_data.time_str);
				}
			}
			setTimeout(()=>{
				if (this.$refs.bar2) {
					this.$refs.bar2.initData(
						[
							{name: '空置率', type: 'bar', color: 'green', data: _bardata.bar1, dw: '%'},
						],
						_bardata.xaxis
					);
				}
			},200)
		},
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuShichangVacancy/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengkuShichangVacancy/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLengkuShichangVacancy/getData',
				data: _where,
				success(res) {
					_this.data = res;

					_this.onChangeVacancyCity();
					_this.onChangePriceCity();

					let _bardata1 = {bar1: [], xaxis: []};

					if(res.vacancy && res.vacancy.now) {
						for (let i in res.vacancy.now) {
							if(_this.$utils.getMarketCityName(i)){
								_bardata1.bar1.push(parseFloat(res.vacancy.now[i]));
								_bardata1.xaxis.push(_this.$utils.getMarketCityName(i));
							}
						}

						setTimeout(()=>{
							if (_this.$refs.bar1) {
								_this.$refs.bar1.initData(
									[
										{name: '空置率', type: 'bar', color: 'blue', data: _bardata1.bar1, dw: '%'},
									],
									_bardata1.xaxis
								);
							}
						},200)
					}
				}
			})
		},
	}
}
</script>
