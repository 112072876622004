<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">进出口口岸相关数据</div>
				</div>
			</div>
		</div>
		<template v-if="data">
			<div class="margin-top-20" v-if="data && data.content1" v-html="data.content1">
				{{data.content1 ? data.content1 : ''}}
			</div>
			<div class="margin-top-20">
				<div style="border-bottom: 2px solid #7ED321;">
					<span class="light bg-green" style="padding:7px 20px;display: inline-block;">全国开放口岸</span>
				</div>
				<div class="margin-top-20 table-layout">
					<div v-if="data.table_content" v-html="data.table_content"></div>
				</div>
			</div>
			<div class="margin-top-20">
				<div style="border-bottom: 2px solid #7ED321;">
					<span class="light bg-green" style="padding:7px 20px;display: inline-block;">各品类指定监管场地</span>
				</div>
				<div class="margin-top-20">
					<el-row :gutter="20">
						<el-col :span="8">
							<div style="border:1px solid #7ed321;">
								<div class="text-center light f18" style="background-color: #7ed321;height: 50px;line-height: 50px;">肉类</div>
								<div class="text-center" style="padding:30px 0">
									<a :href="$utils.formatImagePath(data.rou_place)" target="_blank">点击下载</a>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div style="border:1px solid #7ed321;">
								<div class="text-center light f18" style="background-color: #7ed321;height: 50px;line-height: 50px;">水果</div>
								<div class="text-center" style="padding:30px 0">
									<a :href="$utils.formatImagePath(data.shuiguo_place)" target="_blank">点击下载</a>
								</div>
							</div>
						</el-col>
						<el-col :span="8">
							<div style="border:1px solid #7ed321;">
								<div class="text-center light f18" style="background-color: #7ed321;height: 50px;line-height: 50px;">冰鲜水产品</div>
								<div class="text-center" style="padding:30px 0">
									<a :href="$utils.formatImagePath(data.bxs_place)" target="_blank">点击下载</a>
								</div>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="margin-top-20">
				<div style="border-bottom: 2px solid #7ED321;">
					<span class="light bg-green" style="padding:7px 20px;display: inline-block;">符合评估审查要求的国家或地区输华肉类产品名单</span>
				</div>
				<div class="margin-top-20">
					<div class="cl"></div>
					<div class="margin-top-20">
						<a :href="data.in_list" target="_blank" style="padding:15px 40px;background-color: #7ED321" class="light box-shadow-green">点击查看 >></a>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<el-empty description="暂无数据"></el-empty>
		</template>
	</div>
</template>

<script>
export default {
	name: "InOutKouan",
	data() {
		return {
			data:null
		}
	},
	created() {
	},
	mounted() {
		this.getTotal1();
	},
	methods: {
		getTotal1() {
			let _this = this;

			this.$http.post({
				url: '/HydataInoutKouan/listAll',
				data: {limit:1,orderBy:'create_time',sortBy:'desc'},
				success(res) {
					if(res && res[0]){
						_this.data = res[0];
					}
				}
			});
		},
	}
}
</script>
<style>
.table-layout table{border:2px solid gray;}
</style>
