<template>
	<div v-if="data">
		<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1 ? data.now.content1 : ''">
			{{data.now.content1 ? data.now.content1 : ''}}
		</div>
		<el-row :gutter="10">
			<el-col :span="12">
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar1"
						ref="bar1"
						:grid-bottom="30"
						:grid-top="30"
						left-name="（万吨）"
						height="350px"
						show-legend
					></lee-bar-echarts>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="text-center margin-top-20">
					<lee-bar-echarts
						key="bar2"
						ref="bar2"
						:grid-bottom="30"
						:grid-top="30"
						left-name="（亿立方米）"
						height="350px"
						show-legend
					></lee-bar-echarts>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: "IndexHyData2",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom: {
				year: '',
				times: '',
				time_type: '',
			},
			searchData: '',
			searchQuarterData: ''
		}
	},
	created() {
		// this.startYear = this.year;
		// this.endYear = this.year - 5;
	},
	mounted() {
		this.getYears();
	},
	methods: {
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengku/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLengku/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchQuarterData && this.searchQuarterData !== '0'){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
				if(this.searchData){
					_where.year = this.searchData;
				}
			}else{
				_where.time_type = 'year';
				if(this.searchData){
					_where.year = [this.searchData,'<=']
				}
			}
			this.$http.post({
				url: '/HydataLengku/getData',
				data: _where,
				success(res) {
					_this.data = res;
					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata2 = {bar1: [], line1: [], xaxis: []};

					for (let i = 0; i < res.list.length; i++) {
						_bardata1.bar1.push(res.list[i].total);
						_bardata1.line1.push(res.list[i].total_rate);
						_bardata1.xaxis.push(res.list[i].time_str);
						_bardata2.bar1.push(res.list[i].amount);
						_bardata2.line1.push(res.list[i].amount_rate);
						_bardata2.xaxis.push(res.list[i].time_str);
					}

					setTimeout(()=>{
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '冷库总库容', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '万吨'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '冷库总容积', type: 'bar', color: 'blue', data: _bardata2.bar1, dw: '亿立方米'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata2.line1, dw: '%'},
								],
								_bardata2.xaxis
							);
						}
					},200)
				}
			})
		},
	}
}
</script>
