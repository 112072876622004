<template>
	<div style="background: linear-gradient(110deg, #01342d, #031b33);padding:50px 0;min-width: 1300px;">
		<div class="padding-top padding-bottom-20 light" :class="$isMobile ? '' : 'wrap1100'">
			<el-row>
				<el-col :span="8">
					<div class="">联系我们</div>
					<div class="green-line margin-top margin-bottom"></div>
					<div class="f12">
						办公地点：{{company_address}}<br/>
						邮编：{{company_zip_code}}<br/>
						联系电话：{{company_phone}}<br/>
					</div>
				</el-col>
				<el-col :span="8">
					<div class="">帮助中心</div>
					<div class="green-line margin-top margin-bottom"></div>
					<div class="f12">
						联系客服：{{service_person}}<br/>商务合作：{{business_person}}
					</div>
				</el-col>
				<el-col :span="8">
					<img src="/images/icon/icon_qq_off.png" height="42"/>
					<img src="/images/icon/icon_weixin_off.png" height="42"/>
					<img src="/images/icon/icon_weibo_off.png" height="42"/>
					<el-popover placement="top" width="230" trigger="hover" v-if="lenglianwei_gzh_qrcode">
						<img :src="$utils.formatImagePath(lenglianwei_gzh_qrcode)" height="200" class="vm"/>
						<img :src="$utils.formatImagePath(lenglianwei_gzh_qrcode)" slot="reference" height="88" class="margin-left-20"/>
					</el-popover>
					<el-popover placement="top" width="230" trigger="hover" v-if="weixin_mini_qrcode">
						<img :src="$utils.formatImagePath(weixin_mini_qrcode)" height="200" class="vm"/>
						<img :src="$utils.formatImagePath(weixin_mini_qrcode)" slot="reference" height="88" class="margin-left-20"/>
					</el-popover>
				</el-col>
			</el-row>
			<div class="text-center f12 margin-top-50">
				copyright 北京中物冷联企业管理有限公司-数据平台-冷链物流专业委员会 ©2018-{{year}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PageFooter",
	data() {
		return {
			year:'2020',
			lenglianwei_gzh_qrcode:'',
			weixin_mini_qrcode:'',
			company_phone:'',
			company_address:'',
			company_zip_code:'',
			service_person:'',
			business_person:'',
		}
	},
	created() {
	},
	mounted() {
		let _time = new Date();
		this.year = _time.getFullYear();

		this.getSetting();
	},
	methods: {
		getSetting(){
			let _this = this;
			this.$http.post({
				url:'/SystemSetting/getSystem',
				data:{skey:['lenglianwei_gzh_qrcode', 'weixin_mini_qrcode', 'company_phone','company_address','company_zip_code','service_person','business_person']},
				success(res){
					_this.lenglianwei_gzh_qrcode = res.lenglianwei_gzh_qrcode;
					_this.weixin_mini_qrcode = res.weixin_mini_qrcode;
					_this.company_phone = res.company_phone;
					_this.company_address = res.company_address;
					_this.company_zip_code = res.company_zip_code;
					_this.service_person = res.service_person;
					_this.business_person = res.business_person;
				}
			})
		}
	}
}
</script>
