<template>
	<lee-page ref="page" abs-bg-img="/images/bg_head_10.jpg" title-class="margin-top-100" :height="$isMobile ? (type === 'light' ? 150 : 50) : 280" :type="$isMobile ? type : 'light'" :back="$isMobile" :abs-title="$isMobile ? '' : '会员中心'" :mini-title="$route.meta">
		<div class="margin-top padding-left-right" v-if="$isMobile">
			<slot></slot>
		</div>
		<div class="bg-img-2 padding-bottom-400 rel" style="background-color:#f8f9fa" v-else>
			<div class="cl"></div>
			<div class="wrap1250" style="margin-top: -70px;">
				<div class="cl">
					<div class="z" style="width: 276px;">
						<div class="padding light bg-dark-25 border-radius-5 text-center line-height">
							<!--                          <span class="avatar hand inline-block rel border-radius hide" style="height: 80px;width: 80px;">-->
							<!--                            <img src="/images/banner_1.jpg" width="80" height="80" class="border-radius"/>-->
							<!--                            <div class="avatar-edit light abs bg-dark-op" style="line-height:80px;height: 80px;width:80px;top:0;left:0;display: none;">-->
							<!--                              修改头像-->
							<!--                            </div>-->
							<!--                          </span>-->
							<head-img-upload v-model="avatar" @change="onChangeAvatar"></head-img-upload>
							<div class="margin-top f18">{{ nickname ? nickname : '尚未设置' }}</div>
							<div class="f20 f-thin">{{ mobile ? mobile : '-' }}</div>
						</div>
						<lee-user-menu></lee-user-menu>
					</div>
					<div class="y padding bg-light border-radius-5" style="width: 964px;">
						<slot></slot>
					</div>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "UserMainPage",
	data() {
		return {
			nickname: '',
			mobile: '',
			avatar: '',
		}
	},
	props: {
		type: {
			type: String,
			default: 'light'
		}
	},
	mounted() {
		this.getMobile();
		this.getNickname();
		this.getAvatar();
	},
	methods: {
		refreshHeader(){
			this.$refs.page.getUserInfo();
		},
		getNickname() {
			this.nickname = this.$user.getNickName();
		},
		getMobile() {
			this.mobile = this.$user.getMobile();
		},
		getAvatar() {
			this.avatar = this.$user.getAvatar();
		},
		onChangeAvatar(e) {
			let _this = this;

			this.$http.post({
				url: '/UserProfile/editAvatar',
				data: {avatar: e},
				success(res) {
					_this.$user.setAvatar(e);
					_this.getAvatar();
				}
			})
		}
	}
}
</script>
<style scoped>
.avatar:hover .avatar-edit {
	display: block !important;
}
</style>
