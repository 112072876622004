<template>
	<div>
		<div class="green-line">
			<div class="f34 flex flex-ai flex-jc-between">
				<div class="flex flex-ai">
					<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
					<div class="f22">{{title}}产销量数据</div>
				</div>
				<div class="flex flex-ai">
					<el-select v-model="searchData" @change="onChangeYear">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
					</el-select>
					<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
						<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="margin-top-20" v-if="data">
			<p v-if="data && data.now && data.now.content1" v-html="data.now.content1">
				{{data.now.content1}}
			</p>
			<div>
				<lee-bar-echarts
					ref="bar1"
					:grid-bottom="30"
					:grid-top="30"
					:left-name="dw"
					height="350px"
					:show-legend="false"
					:show-left="true"
					:show-right="false"
				></lee-bar-echarts>
			</div>
			<table class="lee-table margin-auto" style="width:100%;" v-if="showTable">
				<thead>
				<td width="200" class="f14">各省份{{ title }}产量{{dw}}</td>
				<td v-for="(itemhd,indexhd) in data.list">{{ getTimeStr(itemhd.year,itemhd.time_type,itemhd.times) }}</td>
				</thead>
				<tr v-for="(aitem,aindex) in areaList">
					<td>{{ aitem.name }}</td>
					<td v-for="(itembd,indexbd) in data.list">{{ parseFloat(itembd[aitem.value]) }}</td>
				</tr>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	name: "ChanliangShuiguo",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:'',
			areaList: [
				{name: '北京', value: 'beijing'},
				{name: '天津', value: 'tianjing'},
				{name: '河北', value: 'hebei'},
				{name: '山东', value: 'shandong'},
				{name: '江苏', value: 'jiangsu'},
				{name: '浙江', value: 'zhejiang'},
				{name: '上海', value: 'shanghai'},
				{name: '广东', value: 'guangdong'},
				{name: '福建', value: 'fujian'},
				{name: '海南', value: 'hainan'},
				{name: '河南', value: 'henan'},
				{name: '安徽', value: 'anhui'},
				{name: '湖北', value: 'hubei'},
				{name: '湖南', value: 'hunan'},
				{name: '江西', value: 'jiangxi'},
				{name: '黑龙江', value: 'heilongjiang'},
				{name: '吉林', value: 'jilin'},
				{name: '辽宁', value: 'liaoning'},
				{name: '新疆', value: 'xinjiang'},
				{name: '甘肃', value: 'gansu'},
				{name: '宁夏', value: 'ningxia'},
				{name: '陕西', value: 'shanxi'},
				{name: '青海', value: 'qinghai'},
				{name: '西藏', value: 'xizang'},
				{name: '山西', value: 'shanxi2'},
				{name: '四川', value: 'sichuan'},
				{name: '重庆', value: 'chongqing'},
				{name: '贵州', value: 'guizhou'},
				{name: '云南', value: 'yunnan'},
				{name: '广西', value: 'guangxi'},
				{name: '内蒙古', value: 'menggu'},
			],
		}
	},
	props:{
		tabType:{
			type:String,
			default:''
		},
		title:{
			type:String,
			default:''
		},
		showTable:{
			type:Boolean,
			default:false
		},
		dw:{
			type:String,
			default:'(万吨)'
		},
		barName:{
			type:String,
			default:'产量(万吨)'
		},
		type:{
			type:String,
			default:''
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeDataType(e){
			this.dataType = e;
		},
		getTimeStr(year,time_type,times){
			let quarterTwo = {'1':'第一季度', '2':'第二季度','3':'第三季度', '4':'第四季度'};
			if(time_type === 'year'){
				return year+'年';
			}else{
				return year+'年'+quarterTwo[times];
			}
		},
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataChanliang/getYears',
				data: {type:this.type},
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataChanliang/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},

		getTotal1() {
			let _this = this;
			let _where = {type:this.type};
			if(this.searchData){
				_where.year = [this.searchData,'<=']
			}
			if(this.searchQuarterData){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
			}else{
				_where.time_type = 'year';
			}
			this.$http.post({
				url: '/HydataChanliang/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata = {bar: [],xaxis:[]};

					for (let i = 0; i < res.list.length; i++) {
						_bardata.bar.push(res.list[i].total);
						_bardata.xaxis.push(res.list[i].time_str);
					}

					setTimeout(()=>{
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: _this.barName, type: 'bar', color: 'green', data: _bardata.bar},
								],
								_bardata.xaxis
							);
						}
					},200)
				}
			});
		},
		formatBar(data, dw = '产量(万吨)') {
			let _bardata = {bar: []};

			for (let i = 0; i < data.length; i++) {
				_bardata.bar.push(data[i].total);
			}

			return {name: this.barName, type: 'bar', color: 'green', data: _bardata.bar};
		},

		formatX(data) {
			let _bardata = {xaxis: []};

			for (let i = 0; i < data.length; i++) {
				_bardata.xaxis.push(data[i].time_str);
			}

			return _bardata.xaxis;
		},
	}
}
</script>
