<!---->
<template>
	<lee-page code="yanjiu" title-class="margin-top-100" bg-img="/images/bg_head_3.jpg" :abs-title="parentInfo.name" sub-title="" des="" :search="!$isMobile" :bread="[{title:'研究报告',url:'/yanjiu'},{title:'细分领域报告',url:'/yanjiu/domain'},{title:parentInfo.name}]" show-year search-time-key="push_time" v-if="parentInfo" height="280">
		<div class="bg-img-2  padding-top-50" style="background-color:#f8f9fa">
			<!--            <div style="width: 1500px;" class="margin-auto margin-top-100">-->
			<!--                <lee-select clearable v-model="year" class="bg-light" :options="searchYearList" style="width: 200px;" @change="changeYear">-->
			<!--                </lee-select>-->
			<!--            </div>-->
			<!--            <div class="cl"></div>-->
			<lee-tabs :list="tabList" :has-icon="false" labelKey="name" valueKey="id" :default-index="mm_type" @change="onTabChange" class="margin-auto" style="max-width: 866px;" v-if="tabList.length > 0"></lee-tabs>
			<div class="padding-bottom-100" v-if="list.length > 0">
				<div class="margin-top-20 margin-auto" :style="{width: '1500px'}">
					<el-row :gutter="10">
						<el-col :md="8" :xs="24" v-for="(item,index) in list" class="margin-bottom">
							<div class="bg-light border-radius-5 hand padding-20" style="height:225px" @click="$router.push({path:(searchForm.type === 'domain' ? '/domain/detail/' : '/industry/detail/')+item.id})">
								<div class="cl"></div>
								<div class="border-bottom padding-bottom" style="height:60px;line-height: 20px;">
									<img src="/images/icon/icon_36.png" width="20" height="20" class="vm"/>
									<div class="f18 margin-left-30" style="height:50px;line-height: 20px;margin-top:-20px;" v-html="item.title"></div>
								</div>
								<el-row :gutter="10" class="margin-top">
									<el-col :md="24" :xs="12" class="margin-bottom" v-if="item.author">
										<img src="/images/icon/icon_37.png" width="20" height="20" class="margin-right"/>
										<span class="vm dark-3 f16">{{ item.author }}</span>
									</el-col>
									<el-col :md="24" :xs="12" class="margin-bottom">
										<img src="/images/icon/icon_38.png" width="20" height="20" class="margin-right"/>
										<span class="vm dark-3 f16" v-if="item.push_time">{{ item.push_time }}</span>
									</el-col>
									<el-col :md="24" :xs="12" class="margin-bottom" v-if="item.content">
										<img src="/images/icon/icon_39.png" width="20" height="20" class="vm"/>
										<div class="margin-left-30 text-line-2 vm f16 dark-3" style="height: 40px;line-height: 20px;margin-top: -20px" v-html="item.content"></div>
									</el-col>
								</el-row>
							</div>
						</el-col>
					</el-row>
				</div>
				<div class="text-center margin-top-100">
					<el-pagination
						background
						layout="pager, jumper"
						:page-size="pageSize"
						:current-page.sync="searchForm.page"
						:total="total"
						@current-change="pageChange">
					</el-pagination>
				</div>
			</div>
			<el-empty description="暂无数据" v-else></el-empty>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Index",
	data() {
		return {
			type: '',
			list: [],
			title: '',
			pageSize: 12,
			total: 0,
			searchForm: {
				page: 1,
				type: 'domain',
				create_time: '',
				type_domainReport_id:''
			},
			pid:'',
			year: '',
			searchYearList: [],
			mm_type: '',
			parentInfo:null,
			tabList: [
				// {label: '冷链', value: 'chain', icon: '/images/icon/icon_57_'},
				// {label: '食材供应链', value: 'agriculture', icon: '/images/icon/icon_58_'},
				// {label:'冷链/食材供应链',value:'all',icon:'/images/icon/icon_57_'},
			],
		}
	},
	created() {
		if (!this.$utils.isEmptyObject(this.$route.query)) {
			this.searchForm = {...this.$route.query,...this.searchForm};
		}

		if(this.searchForm.mmtype){
			this.searchForm.type_domainReport_id = this.searchForm.mmtype;
		}

		this.pid = this.$route.params.pid;
		this.year = this.$route.query.year ? parseInt(this.$route.query.year) : '';
		this.searchForm.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;

		let _nowYear = this.$moment().year();

		for (let i = _nowYear; i > _nowYear - 10; i--) {
			this.searchYearList.push({value: i.toString(), label: i.toString()});
		}

	},
	mounted() {
		// this.getTypeList();
		this.getTypeDetail();
	},
	methods: {
		// changeYear(e){
		//     if(e) {
		//         let _start = e + '-1-1 00:00:00'; //this.$moment(e+'-1-1 00:00:00').unix();
		//         let _end = e + '-12-31 23:59:59'; //this.$moment(e+'-12-31 23:59:59').unix();
		//
		//         this.searchForm.create_time = [_start, _end];
		//     }else{
		//         this.searchForm.create_time = '';
		//     }
		//
		//     this.$router.replace({query:{...this.searchForm,year:this.year}});
		//     // this.getList();
		//     // console.log(this.$moment(e+'-12-31 23:59:59').unix());
		// },
		onTabChange(i) {
			console.log(i)
			this.mm_type = i.value;
			// this.getList();
		},
		getTypeList(pid) {
			let _this = this;

			this.$http.post({
				url: '/Type/listAll',
				data: {akey:'domainReport', pid:pid, state:1,orderBy:'sort',sortBy:'asc'},
				success(res) {
					let _tabs = [{akey:'domainReport',name:'全部',id:''}];

					_this.tabList = _tabs.concat(res);
					if(!_this.searchForm.type_domainReport_id){
						let _ids = [];
						res.forEach(t=>{
							_ids.push(t.id);
						})
						_this.searchForm.type_domainReport_id = [_ids,'in'];
					}
					_this.getList();
				}
			})
		},
		getTypeDetail(){
			let _this = this;

			if(this.pid) {
				this.$http.post({
					url: '/Type/detail',
					data: {id:this.pid},
					success(res) {
						console.log(_this.searchForm);
						_this.parentInfo = res;
						if(res.avalue === 'type') {
							_this.getTypeList(res.id);
						}else{
							_this.searchForm.type_domainReport_id = res.id;
							_this.getList();
						}
					}
				});
			}
		},
		getList() {
			let _this = this;

			this.$http.post({
				url: '/Report/listPage',
				data: {...this.searchForm, pageSize: this.pageSize,orderBy:'push_time',sortBy:'desc'},
				success(res) {
					_this.list = res.list;
					_this.total = res.totalCount;
				}
			})
		},
		pageChange(page) {
			this.searchForm.page = page;
			this.$router.replace({query: {...this.searchForm, year: this.year}});
		},
	}
}
</script>
