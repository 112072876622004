<template>
	<lee-page abs-bg-img="/images/bg_head_10.jpg" title-class="margin-top-100" :height="280" abs-title="重置密码">
		<div class="bg-img-2 padding-top-100 padding-bottom-100">
			<div class="cl"></div>
			<div class="margin-auto bg-light border-radius-5 padding-20" style="width:500px;margin-top: -30px;">
				<div class="green cl border-bottom padding-bottom" v-if="step < 4">
					重置密码
					<el-button size="mini" type="success" round plain class="y vm" @click="$router.push({path:'/login'})">
						账号密码登录<i class="fa fa-qrcode margin-left" aria-hidden="true"></i></el-button>
				</div>
				<el-form :model="formData" status-icon ref="ruleForm" label-width="auto" class="margin-top-30" v-if="step === 1">
					<el-form-item label="" prop="username">
						<el-input v-model="formData.username" autocomplete="off" class="lee-input mobile" placeholder="用户名"></el-input>
					</el-form-item>
					<el-form-item label="" prop="v_code">
						<el-input v-model="formData.code" autocomplete="off" class="lee-input mobile margin-right" placeholder="验证码" style="width: 300px;" maxlength="4"></el-input>
						<img :src="verifyCode" style="width: 150px;height:40px;" @click="getCode"/>
					</el-form-item>
					<el-form-item class="margin-top-30">
						<el-button type="success" class="block" @click="toCheckCode">下一步</el-button>
					</el-form-item>
				</el-form>
				<el-form :model="formData2" status-icon ref="ruleForm" label-width="auto" class="margin-top-30" v-if="step === 2">
					<el-form-item label="" prop="mobile">
						<lee-cell :title="'验证手机：'+formData2.mobile"></lee-cell>
					</el-form-item>
					<el-form-item label="" prop="code">
						<el-input v-model="formData2.code" autocomplete="off" class="lee-input mobile margin-right" placeholder="手机验证码" maxlength="6" style="width: 300px;"></el-input>
						<el-button @click="sendCode" :disabled="disSms" type="success" class="text-center box-shadow-green" style="width: 150px;">
							{{ codeText }}
						</el-button>
					</el-form-item>
					<el-form-item class="margin-top-30">
						<el-button type="success" class="block" @click="toCheck">下一步</el-button>
					</el-form-item>
				</el-form>
				<el-form :model="formData3" status-icon :rules="rules" ref="ruleForm3" label-width="auto" class="margin-top-30" v-show="step === 3">
					<el-form-item label="" prop="new_pass">
						<el-input v-model="formData3.new_pass" type="password" show-password autocomplete="off" class="lee-input mobile" placeholder="设置新密码"></el-input>
					</el-form-item>
					<el-form-item label="" prop="re_new_pass">
						<el-input v-model="formData3.re_new_pass" type="password" show-password autocomplete="off" class="lee-input mobile" placeholder="确认新密码"></el-input>
					</el-form-item>
					<el-form-item class="margin-top-30">
						<el-button type="success" class="block" @click="changePass">下一步</el-button>
					</el-form-item>
				</el-form>
				<div class="text-center margin-top-30" v-if="step === 4">
					<div class="bg-green margin-auto light border-radius" style="width:60px;height: 60px;line-height: 80px;">
						<i class="el-icon-check f40"></i>
					</div>
					<div class="dark-2 f18 margin-top-20 text-center">密码修改成功</div>
					<el-button type="success" class="margin-top-70 block" @click="$router.push({path:'/login'})">去登陆</el-button>
				</div>
			</div>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "List",
	data() {
		let checkRePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.formData3.new_pass) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			step: 1,
			verifyCode: '',
			disSms: false,
			codeText: '发送验证码',
			time: 60,
			timeId: null,
			formData: {
				username: '',
				code: '',
				verifyCodeTime: '',
				verifyCodeHash: ''
			},
			formData2: {
				mobile: '',
				code: ''
			},
			formData3: {
				mobile: '',
				spm: '',
				new_pass: '',
				re_new_pass: ''
			},
			rules: {
				new_pass: [
					{required: true, message: '请输入密码'},
					{min: 6, max: 20, message: '密码必须是6-20位字符', trigger: 'change'},
					{pattern: this.$regexp.password, message: '密码只能包含数字、字母及特殊字符(!#$%^&*.~,)', trigger: 'change'}
				],
				re_new_pass: [
					{required: true, message: '请重复输入密码'},
					{min: 6, max: 20, message: '密码必须是6-20位字符', trigger: 'change'},
					{pattern: this.$regexp.password, message: '密码只能包含数字、字母及特殊字符(!#$%^&*.~,)', trigger: 'change'},
					{validator: checkRePass, message: '两次输入密码不一致!', trigger: 'change'}
				],
			}
		}
	},
	created() {

	},
	mounted() {
		this.getCode();
	},
	methods: {
		getCode() {
			let _this = this;
			this.$http.post({
				url: '/VerifyCode',
				data: null,
				success(res) {
					_this.verifyCode = res.verifyCode;
					_this.formData.verifyCodeTime = res.verifyCodeTime;
					_this.formData.verifyCodeHash = res.verifyCodeHash;
				}
			})
		},
		toCheckCode() {
			let _this = this;

			if (!this.formData.username) {
				this.$message.error('请输入用户名');
				return;
			}

			if (!this.formData.code) {
				this.$message.error('请输入验证码');
				return;
			}

			this.$http.post({
				url: '/Common/checkVerifyCode',
				data: this.formData,
				success(res) {
					if (res.mobile) {
						_this.formData2.mobile = res.mobile;
						_this.formData3.mobile = res.mobile;
						_this.step = 2;
					}
				},
				fail(e) {
					_this.$message.error(e.msg);
					_this.getCode();
				}
			})
		},
		toCheck() {
			let _this = this;
			this.$http.post({
				url: '/Common/checkSms',
				data: this.formData2,
				success: (res) => {
					if (res.spm) {
						_this.formData3.spm = res.spm;
						_this.step = 3;
					}
				}
			});
		},
		sendCode() {
			let _this = this;
			this.$http.post({
				url: '/Common/sendSms',
				data: this.formData2,
				success: (res) => {
					if (res === 'ok') {
						_this.disSms = true;
						_this.startTime();
						_this.$message.success('验证码发送成功');
					}
				}
			});
		},
		timeDown() {
			if (this.time > 0) {
				this.time -= 1;
				this.codeText = this.time + 's 重新获取'
			} else {
				this.disSms = false;
				clearInterval(this.timeId);
				this.timeId = null;
				this.codeText = '发送验证码'
			}
		},
		startTime() {
			this.time = 60;
			this.timeId = setInterval(this.timeDown, 1000);
		},
		changePass() {
			let _this = this;

			this.$refs['ruleForm3'].validate(valid => {
				if (valid) {
					_this.$http.post({
						url: '/Common/changePass',
						data: this.formData3,
						success: (res) => {
							if (res === 'ok') {
								_this.$utils.sessionClear();
								_this.$message.success('密码重置成功');
								_this.step = 4;
							}
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		}
	},
	destroyed() {
		if (this.timeId) {
			clearInterval(this.timeId);
			this.timeId = null
		}
	}
}
</script>
