<template>
	<lee-page code="hydata" bg-img="/images/bg_head_3.jpg" type="light" title-class="margin-top-100" height="250" abs-title="冷链设施设备相关数据" :bread="[{title:'行业数据',url:'/hydata'},{title:'冷链设施设备'}]">
		<div class="bg-img-2  padding-top-50" :style="{backgroundColor:'#f8f9fa'}">
			<check-vip akey="hangyedata_shebei">
				<div class="wrap1100 padding-bottom-100">
					<div class="green-line">
						<div class="f34 flex flex-ai flex-jc-between">
							<div class="flex flex-ai">
								<lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right"></lee-img-icon>
								<div class="f22">冷链设施设备相关数据</div>
							</div>
							<div class="flex flex-ai">
								<el-select v-model="searchData" @change="onChangeYear">
									<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in yearList"></el-option>
								</el-select>
								<el-select class="margin-left" v-model="searchQuarterData" @change="onChangeQuarter" v-if="quarterList.length > 0">
									<el-option :value="tt.value" :label="tt.timeStr" v-for="(tt,ii) in quarterList"></el-option>
								</el-select>
							</div>
						</div>
					</div>
					<div class="margin-top-20" v-if="data && data.now && data.now.content1" v-html="data.now.content1">
						{{data.now.content1 ? data.now.content1 : ''}}
					</div>
					<div class="margin-top-20">
						<div style="border-bottom: 2px solid #7ED321;">
							<span class="light bg-green" style="padding:7px 20px;display: inline-block;">叉车市场规模分析</span>
						</div>
						<div class="text-center margin-top-20">
							<lee-bar-echarts
								key="bar1"
								ref="bar1"
								:grid-bottom="30"
								height="350px"
								show-legend
								:grid-top="30"
								left-name="(万辆)"
							></lee-bar-echarts>
						</div>
					</div>
					<div class="margin-top-20">
						<div style="border-bottom: 2px solid #7ED321;">
							<span class="light bg-green" style="padding:7px 20px;display: inline-block;">货架市场规模分析</span>
						</div>
						<div class="text-center margin-top-20">
							<lee-bar-echarts
								key="bar2"
								ref="bar2"
								:grid-bottom="30"
								height="350px"
								show-legend
								:grid-top="30"
								left-name="(亿元)"
							></lee-bar-echarts>
						</div>
					</div>
					<div class="margin-top-20">
						<div style="border-bottom: 2px solid #7ED321;">
							<span class="light bg-green" style="padding:7px 20px;display: inline-block;">制冷压缩机市场规模分析</span>
						</div>
						<div class="text-center margin-top-20">
							<lee-bar-echarts
								key="bar3"
								ref="bar3"
								:grid-bottom="30"
								height="350px"
								show-legend
								:grid-top="30"
								left-name="(亿元)"
							></lee-bar-echarts>
						</div>
					</div>
<!--					<div class="margin-top-20">-->
<!--						<div style="border-bottom: 2px solid #7ED321;">-->
<!--							<span class="light bg-green" style="padding:7px 20px;display: inline-block;">冷机市场规模分析</span>-->
<!--						</div>-->
<!--						<div class="text-center margin-top-20">-->
<!--							<lee-bar-echarts-->
<!--								key="bar4"-->
<!--								ref="bar4"-->
<!--								:grid-bottom="30"-->
<!--								height="350px"-->
<!--								show-legend-->
<!--								:grid-top="30"-->
<!--								left-name="(亿元)"-->
<!--							></lee-bar-echarts>-->
<!--						</div>-->
<!--					</div>-->
					<div class="margin-top-20">
						<div style="border-bottom: 2px solid #7ED321;">
							<span class="light bg-green" style="padding:7px 20px;display: inline-block;">展示柜市场规模分析</span>
						</div>
						<div class="text-center margin-top-20">
							<lee-bar-echarts
								key="bar5"
								ref="bar5"
								:grid-bottom="30"
								height="350px"
								show-legend
								:grid-top="30"
								left-name="(亿元)"
							></lee-bar-echarts>
						</div>
					</div>
				</div>
			</check-vip>
		</div>
	</lee-page>
</template>

<script>
export default {
	name: "Shebei",
	data() {
		return {
			yearList: [],
			quarterList: [],
			data: null,
			searchFrom:{
				year:'',
				times:'',
				time_type:'',
			},
			searchData:'',
			searchQuarterData:'',
		}
	},
	created() {
	},
	mounted() {
		this.getYears();
	},
	methods: {
		onChangeTimeType(e){
			this.searchData = '';
			this.getYears();
		},
		onChangeYear(e){
			this.getQuarter()
		},
		onChangeQuarter(e){
			this.getTotal1();
		},
		getYears() {
			let _this = this;

			this.$http.post({
				url: '/HydataLenglianShebei/getYears',
				data: null,
				success(res) {
					_this.yearList = res;
					_this.searchData = res[0].value;
					_this.getQuarter();
				}
			});
		},
		getQuarter() {
			let _this = this;

			this.$http.post({
				url: '/HydataLenglianShebei/getQuarter',
				data: {year:this.searchData},
				success(res) {
					if(res.length > 0) {
						_this.quarterList = res;
						_this.searchQuarterData = res[0].value;
					}else{
						_this.quarterList = [];
						_this.searchQuarterData = '';
					}
					_this.getTotal1();
				}
			});
		},
		getTotal1() {
			let _this = this;
			let _where = {};
			if(this.searchData){
				_where.year = [this.searchData,'<=']
			}
			if(this.searchQuarterData){
				_where.times = [this.searchQuarterData,'<=']
				_where.time_type = 'quarter';
			}else{
				_where.time_type = 'year';
			}
			this.$http.post({
				url: '/HydataLenglianShebei/getData',
				data: _where,
				success(res) {
					_this.data = res;

					let _bardata1 = {bar1: [], line1: [], xaxis: []};
					let _bardata2 = {bar1: [], line1: [], xaxis: []};
					let _bardata3 = {bar1: [], line1: [], xaxis: []};
					let _bardata4 = {bar1: [], line1: [], xaxis: []};
					let _bardata5 = {bar1: [], line1: [], xaxis: []};

					for (let i = 0; i < res.list.length; i++) {
						_bardata1.bar1.push(parseFloat(res.list[i].amount));
						_bardata1.line1.push(parseFloat(res.list[i].amount_rate));
						_bardata1.xaxis.push(res.list[i].time_str);

						_bardata2.bar1.push(parseFloat(res.list[i].amount2));
						_bardata2.line1.push(parseFloat(res.list[i].amount2_rate));
						_bardata2.xaxis.push(res.list[i].time_str);

						_bardata3.bar1.push(parseFloat(res.list[i].amount3));
						_bardata3.line1.push(parseFloat(res.list[i].amount3_rate));
						_bardata3.xaxis.push(res.list[i].time_str);

						_bardata4.bar1.push(parseFloat(res.list[i].amount4));
						_bardata4.line1.push(parseFloat(res.list[i].amount4_rate));
						_bardata4.xaxis.push(res.list[i].time_str);

						_bardata5.bar1.push(parseFloat(res.list[i].amount5));
						_bardata5.line1.push(parseFloat(res.list[i].amount5_rate));
						_bardata5.xaxis.push(res.list[i].time_str);
					}

					setTimeout(()=>{
						if (_this.$refs.bar1) {
							_this.$refs.bar1.initData(
								[
									{name: '叉车市场规模', type: 'bar', color: 'green', data: _bardata1.bar1, dw: '万辆'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata1.line1, dw: '%'},
								],
								_bardata1.xaxis
							);
						}

						if(_this.$refs.bar2) {
							_this.$refs.bar2.initData(
								[
									{name: '货架市场规模', type: 'bar', color: 'blue', data: _bardata2.bar1, dw: '亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata2.line1, dw: '%'},
								],
								_bardata2.xaxis
							);
						}
						if(_this.$refs.bar3) {
							_this.$refs.bar3.initData(
								[
									{name: '压缩机市场规模', type: 'bar', color: 'green', data: _bardata3.bar1, dw: '亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata3.line1, dw: '%'},
								],
								_bardata3.xaxis
							);
						}
						if(_this.$refs.bar4) {
							_this.$refs.bar4.initData(
								[
									{name: '冷机市场规模', type: 'bar', color: 'blue', data: _bardata4.bar1, dw: '亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata4.line1, dw: '%'},
								],
								_bardata4.xaxis
							);
						}
						if(_this.$refs.bar5) {
							_this.$refs.bar5.initData(
								[
									{name: '展示柜市场规模', type: 'bar', color: 'green', data: _bardata5.bar1, dw: '亿元'},
									{name: '同比', type: 'line', color: 'purple', data: _bardata5.line1, dw: '%'},
								],
								_bardata5.xaxis
							);
						}
					},200)
				}
			})
		},
	}
}
</script>
