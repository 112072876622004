<template>
    <lee-page code="zhengce" bg-img="/images/bg_head_2.jpg" title="政策标准" sub-title="Policy Standard" height="526" des="" :search="true" :back="false" :bread="[{title:'政策标准',url:'/zhengce'},{title:'搜索'}]">
        <div class="margin-top-50 margin-auto" style="width: 866px" slot="search">
            <lee-search show-year search-time-key="push_time" show-policy></lee-search>
        </div>
        <div class="bg-img-2  padding-top-50" style="background-color: #f8f9fa;">
            <div class="wrap1100 padding-bottom-100">
                <template v-if="list.length > 0">
	                <div class="text-center dark-3 padding-bottom-30">共查询到 <b class="red">{{total}}</b> 条相关内容</div>
                    <div class="box-shadow bg-light margin-bottom-20 hand" v-for="(item,index) in list" @click="$router.push({path:'/'+item.type+'/detail/'+item.id})">
                        <img :src="item.head_img ? $config.assetsUrl+item.head_img : '/images/none.jpg'" width="150" height="150" class="vm"/>
                        <div class="hide" style="height:150px;padding-left:150px;margin-top:-150px">
                            <div class="margin">
                                <div class="cl">
                                    <div class="z green-line padding-right-20" style="line-height: 30px;">
                                        <img src="/images/icon/icon_30.png" height="22" class="margin-right"/>
                                        <span class="dark-0 vm f18 f-b" v-html="item.title"></span>
                                    </div>
                                    <div class="y green f14" style="line-height: 30px;">查看更多</div>
                                </div>
                                <div class="f14 dark-3 text-line-2" v-html="item.content"></div>
															<div class="f14 margin-top" style="color: #ccc;" v-if="item.push_time">{{item.push_time}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center margin-top-100">
                        <el-pagination
                            background
                            layout="pager, jumper"
                            :page-size="pageSize"
                            :current-page.sync="searchForm.page"
                            :total="total"
                            @current-change="pageChange">
                        </el-pagination>
                    </div>
                </template>
	            <div class="text-center dark-3" v-else>
		            未查询到相关内容
	            </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            title:'',
            list:[],
            pageSize:10,
            total:0,
            searchForm:{
                page:1,
                title:'',
                title_type:''
            }
        }
    },
    created() {
        if(!this.$utils.isEmptyObject(this.$route.query)){
            this.searchForm = {...this.$route.query};
        }

        this.searchForm.page = this.searchForm.page ? parseInt(this.searchForm.page) : 1;
        this.searchForm.title = this.$route.query.title ? this.$route.query.title : '';
        this.searchForm.title_type = this.$route.query.title_type ? this.$route.query.title_type : '';
    },
    mounted() {
        this.getList();
    },
    methods:{
        getList(){
            let _this = this;

            this.$http.post({
                url: '/Policy/listPage',
                data: {...this.searchForm,pageSize:this.pageSize,orderBy:'push_time',sortBy:'desc'},
                success(res) {
                    _this.list = res.list;
                    _this.total = res.totalCount;
                }
            });
        },
        pageChange(page) {
            this.searchForm.page = page;
            this.$router.replace({query:{...this.searchForm}});
        },
        onSearch(e){

        }
    },
}
</script>
