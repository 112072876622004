<template>
  <span>
    <el-button :size="size"  :type="type" class="margin-left" @click="showDialog">{{text}}</el-button>
    <login-dialog v-model="show"></login-dialog>
  </span>
</template>

<script>
export default {
  name: "LoginButton",
  data() {
    return {
      show:false,
    }
  },
	props:{
		size:{
			type:String,
			default:'small'
		},
		type:{
			type:String,
			default:'warning'
		},
		text:{
			type:String,
			default:'立即登录'
		}
	},
  created() {
  },
  mounted() {
  },
  methods: {
    showDialog(){
      this.show = true;
    },
    hideDialog(){
      this.show = false;
    }
  }
}
</script>
