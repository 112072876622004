let dev = {
    apiUrl:'http://127.0.0.1:9505/Api',
    assetsUrl:'http://127.0.0.1:9505',
    uploadUrl:'http://127.0.0.1:9505/Api/Upload/upload',
    webUrl:'http://test.chaincloud.org.cn',
    
    // apiUrl:'http://api.chaincloud.org.cn/Api',
    // assetsUrl:'http://api.chaincloud.org.cn/static',
    // uploadUrl:'http://api.chaincloud.org.cn/Api/Upload/upload',
    // webUrl:'http://www.chaincloud.org.cn',
}

let pro = {
    // apiUrl:'//api.test.chaincloud.org.cn/Api',
    // assetsUrl:'//api.test.chaincloud.org.cn/static',
    // uploadUrl:'//api.test.chaincloud.org.cn/Api/Upload/upload',
    // webUrl:'http://test.chaincloud.org.cn',
    
    apiUrl:'//api.chaincloud.org.cn/Api',
    assetsUrl:'//api.chaincloud.org.cn/static',
    uploadUrl:'//api.chaincloud.org.cn/Api/Upload/upload',
    webUrl:'//www.chaincloud.org.cn',
}
export default {
    //线上生产
    // apiUrl:'//api.chaincloud.org.cn/Api',
    // assetsUrl:'//api.chaincloud.org.cn/static',
    // uploadUrl:'//api.chaincloud.org.cn/Api/Upload/upload',

    //本地测试
    

    //线上测试
    
    apiUrl:process.env.NODE_ENV === 'development' ? dev.apiUrl : pro.apiUrl,
    assetsUrl:process.env.NODE_ENV === 'development' ? dev.assetsUrl : pro.assetsUrl,
    uploadUrl:process.env.NODE_ENV === 'development' ? dev.uploadUrl : pro.uploadUrl,
    webUrl:process.env.NODE_ENV === 'development' ? dev.webUrl : pro.webUrl,

    // assetsUrl:'http://127.0.0.1:9501',

    secret:'miihe!@#',

    WX_CONFIG:{
        appId: 'wxbded2c85603efad4'
    },
    topMenu:[
        {title:'首页',path:'/'},
        {title:'资讯',path:'/news'},
        // {title:'企业数据库',path:'/company'},
        {title:'行业数据',path:'/hydata'},
        // {title:'奖项申报',path:'/survey'},
        {title:'申报',path:'/prize'},
        {title:'政策/标准',path:'/zhengce'},
        {title:'研究报告',path:'/yanjiu'},
        {title:'关于我们',path:'/about'},
        {title:'冷库计算器',path:'/calculator'},
        // {title:'企业盘点',path:'/prize'},
        
        // {title:'问答中心',path:'/qa'},
        
        // {title:'会员中心',path:'/user'},
        // {title:'留言板',path:'/comment'},
        // {title:'展示柜',path:'/hydata/cabinet'},
        
        // {title:'问卷调查',path:'/survey'},
    ],
    userMenu:[
        {title:'个人资料',path:'/user/profile',icon:'/images/icon/user/icon_user_1',icon2:'/images/icon/user/icon_user_9.png'},
        {title:'我的企业',path:'/user/company',icon:'/images/icon/user/icon_user_1',icon2:'/images/icon/user/icon_user_9.png'},
        {title:'我的VIP',path:'/user/vip',icon:'/images/icon/user/icon_user_8',icon2:'/images/icon/user/icon_user_16.png'},
        {title:'账号安全',path:'/user/safe',icon:'/images/icon/user/icon_user_2',icon2:'/images/icon/user/icon_user_10.png'},
        {title:'消费记录',path:'/user/buylog',icon:'/images/icon/user/icon_user_3',icon2:'/images/icon/user/icon_user_11.png'},
        {title:'发票管理',path:'/user/fapiao',icon:'/images/icon/user/icon_user_3',icon2:'/images/icon/user/icon_user_11.png'},
        {title:'我的余额',path:'/user/balance',icon:'/images/icon/user/icon_user_3',icon2:'/images/icon/user/icon_user_11.png'},
        {title:'阅读记录',path:'/user/readlog',icon:'/images/icon/user/icon_user_4',icon2:'/images/icon/user/icon_user_12.png'},
        {title:'我的评论',path:'/user/mycomment',icon:'/images/icon/user/icon_user_5',icon2:'/images/icon/user/icon_user_13.png'},
        {title:'我的提问',path:'/qa/me',icon:'/images/icon/user/icon_user_6',icon2:'/images/icon/user/icon_user_14.png'},
        {title:'我的收藏',path:'/user/mycollection',icon:'/images/icon/user/icon_user_7',icon2:'/images/icon/user/icon_user_15.png'},
        {title:'我的点赞',path:'/user/mygood',icon:'/images/icon/user/icon_user_8',icon2:'/images/icon/user/icon_user_16.png'},
    ]
}
