<template>
	<div :style="{width:width,height:height}" class="rel">
		<div id="china" :style="{width:width,height:height}"></div>
		<el-tooltip class="item" effect="dark" :content="showBigHaiNan ? '点击缩小' : '点击放大'" placement="top">
			<img src="./cn_nanhai.jpg" class="abs hand border-w-1 border-dark-4" :style="{height:showBigHaiNan ? '100%' : '30%'}" style="bottom:0;right:0;transition: all .5s;-moz-transition: all .5s;-webkit-transition: all .5s;-o-transition: all .5s; " @click="toggleBigHaiNan"/>
		</el-tooltip>
	</div>
</template>

<script>
export default {
	name: "ColorChinaEcharts",
	props: {
		width: {
			type: [String, Number],
			default: '100%'
		},
		height: {
			type: [String, Number],
			default: '600px'
		},
		max: {
			type: Number,
			default: 9999
		},
		dw: {
			type: String,
			default: ''
		},
		selected: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			showBigHaiNan: false,
			charts: null,
			zoom: 1.2,
			maxZoom: 5,
			minZoom: 1,
			option: {
				visualMap: {
					dimension: 0,
					type: 'continuous',
					min: 0,
					max: 999999,
					left: 'left',
					top: 'bottom',
					hoverLink: false,
					selectedMode: false,
					calculable: false,
					realtime: false,
					text: ['High', '0'],
					// splitNumber: 5,
					// itemWidth: 30,
					// itemGap: 5,
					// itemHeight: 20,
					// textStyle: {
					// 	fontSize: 14,
					// 	fontWeight: 'bold',
					// },
					// pieces: [
					// 	{
					// 		min: 400000000,
					// 		label: '>= 4亿元'
					// 	},
					// 	{
					// 		min: 250000000,
					// 		max: 399999999,
					// 		label: '2.5亿-4亿元'
					// 	},
					// 	{
					// 		min: 150000000,
					// 		max: 249999999,
					// 		label: '1.5亿-2.5亿元'
					// 	},
					// 	{
					// 		min: 50000000,
					// 		max: 149999999,
					// 		label: '5000万-1.5亿'
					// 	},
					// 	// {value: 123, label: '123（自定义特殊颜色）', color: 'grey'}, // 表示 value 等于 123 的情况。
					// 	{
					// 		min: 100,
					// 		max: 499,
					// 		label: '100-500'
					// 	},
					// 	{
					// 		max: 99,
					// 		label: '< 100'
					// 	}
					// ],
					inRange: {
						color: [
							'#e0ecd6',
							// '#e7e1e1',
							'#feea95',
							'#ffd41d',
							'#feb91c',
							'#ff971b',
							'#ff6813',
						]
					}
				},
				grid: {
					top: 0,
					bottom: 0
				},
				tooltip: {
					show: true,
					trigger: 'item',
					showDelay: 0,
					transitionDuration: 0.2,
					formatter: '{b}<br/>{c}'
					// formatter: function (params) {
					// 	console.log(params)
					// 	let _name = params.name;
					//
					// 	if (params.data) {
					// 		let _data = params.data;
					//
					// 		let _value = _data.value;
					//
					// 		let _vals = [];
					//
					// 		for (let i = 0; i < _value.length; i++) {
					// 			_vals.push(_value[i].name + ':' + _value[i].value);
					// 		}
					//
					// 		let _valStr = _vals.join('<br/>');
					// 		return _name + '<br/>' + _valStr;
					// 	} else {
					// 		return _name + '<br/>' + '测试数据：2232<br/>测试数据：2232<br/>测试数据：2232';
					// 	}
					// }
				},
				toolbox: {
					show: true,
					orient: 'vertical',
					right: 0,
					top: 'middle',
					itemSize: 40,
					feature: {
						myscaleInc: {
							show: true,
							title: '放大',
							icon: 'image://' + require('@/assets/images/icon_plus.png'),
							onclick: () => {
								this.scaleInc();
							}
						},
						myscaleDec: {
							show: true,
							title: '缩小',
							icon: 'image://' + require('@/assets/images/icon_dec.png'),
							onclick: () => {
								this.scaleDec();
							}
						}
					}
				},
				// itemStyle: {
				// 	normal: {
				// 		areaColor: '#323c4800', //地图颜色
				// 		borderColor: '#0258b6',
				// 		borderWidth: 1
				// 	},
				// 	// emphasis: {
				// 	//   areaColor: '#2a333d'
				// 	// }
				// },
				// select:{
				//     label:{
				//         color:'#fff'
				//     },
				//     itemStyle: {
				//         areaColor:'#599f22'
				//     }
				// },
				// emphasis:{
				//     label:{
				//         color:'#fff'
				//     },
				//     itemStyle: {
				//         areaColor:'#599f22'
				//     }
				// },
				series: [
					{
						// showLegendSymbol:true,
						name: '',
						type: 'map',
						roam: 'move',
						map: 'China',
						emphasis:{
							label:{
								color:'#000'
							},
							itemStyle: {
								areaColor:'#a5e4fd'
							}
						},
						label: {
							show:true
						},
						itemStyle: {
							areaColor: '#dfedd6', //地图颜色
							borderColor: '#fff',
							borderWidth: 1
						},
						select:{
							label:{
								color:'#000'
							},
							itemStyle: {
								areaColor:'#a5e4fd'
							}
						},
						// nameProperty: 'id',
						// 文本位置修正
						// textFixed: {
						//     42: [20, -20]
						// },
						data: [],
						// nameMap: {
						//     '42': '湖北省',
						// }
					}
				]
			}
		}
	},
	created() {
		this.option.series[0].zoom = this.zoom;

		this.option.series[0].roam = this.$isMobile ? true : 'move';

		if (this.$isMobile) {
			this.option.toolbox.feature = {};
		}
		this.option.tooltip.formatter += this.dw;

		if(this.selected){
			// this.option.series[0].selected = {[this.selected]:true}
		}
		// this.option.visualMap.max = this.max;
	},
	mounted() {
		this.myEcharts();
	},
	methods: {
		initData(data){
			// console.log(data)
			// this.option.series.data = data;
			let maxValue = Math.max(...data.map(obj => obj.value));
			if(this.selected) {
				let _index = 0;
				for (let i=0;i < data.length;i++){
					if(this.selected === data[i].name){
						_index = i;
						data[i].selected = true;
					}else{
						data[i].selected = false;
					}
				}
				// let _index = data.findIndex(t=>t.name === this.selected);
				let _id = this._searchCode(this.selected);
				// data[_index].selected = true;
				this.$emit('onload', {province_code: _id, province_name: data[_index].name});
			}

			this.charts.setOption({series:{data:data},visualMap:{max:maxValue,text:[maxValue,'0']}});
		},
		toggleBigHaiNan() {
			this.showBigHaiNan = !this.showBigHaiNan;
		},
		scaleInc() {
			console.log('放大');
			this.zoom += 1;

			this.charts.setOption({series: {zoom: this.zoom >= this.maxZoom ? this.maxZoom : this.zoom}});
		},
		scaleDec() {
			console.log('放大');
			this.zoom -= 1;

			this.charts.setOption({series: {zoom: this.zoom <= this.minZoom ? this.minZoom : this.zoom}});
		},
		myEcharts() {
			let _this = this;
			// console.log(this.$china);
			// let jss = require('../assets/js/china');
			// console.log(jss);
			// 基于准备好的dom，初始化echarts实例
			this.charts = this.$echarts.init(document.getElementById('china'));

			this.$echarts.registerMap('China', this.$china);

			// console.log(this.$china.features);
			//指定图表的配置项和数据

			this.charts.on('click', function (param) {
				let _id = _this._searchCode(param.name);

				_this.$emit('click', {province_code: _id, province_name: param.name});
			});

			// 使用刚指定的配置项和数据显示图表。
			this.charts.setOption(this.option);
		},
		_searchCode(name) {
			let _list = this.$china.features;

			for (let i = 0; i < _list.length; i++) {
				if (_list[i].properties.name === name) {
					return _list[i].properties.id;
				}
			}

			return null
		}
	}
}
</script>
