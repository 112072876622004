<template>
	<div id="page">
		<page-header ref="header" :height="height" :des-class="desClass" :des-style="desStyle" :title-class="titleClass" :title-style="titleStyle" :sub-title-class="subTitleClass" :sub-title-style="subTitleStyle" :search="search" :search-path="searchPath" :search-slugs="searchSlugs" :search-time-key="searchTimeKey" :show-year="showYear" :show-mm-type="showMmType" :show-policy="showPolicy" :code="code" :abs-title="absTitle" :abs-sub-title="absSubTitle" :abs-des="absDes" :abs-bg-img="absBgImg">
			<template slot="top">
				<slot name="top"></slot>
			</template>
			<template slot="search">
				<slot name="search"></slot>
			</template>
		</page-header>
		<div style="width: 866px;height: 40px;margin-top:-20px;line-height:40px;" class="padding-left-right border-radius-5 margin-auto bg-light box-shadow rel cl" v-if="bread.length > 0 && !$isMobile">
			<div class="z f16">
				<i class="el-icon-location-outline f20"></i>
				当前位置：
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right" style="line-height:40px;">
				<el-breadcrumb-item :to="{ path: '/' }" class="f16 dark-3">首页</el-breadcrumb-item>
				<el-breadcrumb-item v-for="(item,index) in bread" class="f16" :class="index + 1 === bread.length ? 'green text-line-1 hide' : 'dark-3'" style="max-width: 430px;" :to="item.url ? { path: item.url } : null">
					{{ item.title }}
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<div :style="{marginTop:bread.length > 0 ? '-20px' : ''}">
			<slot @refreshHeader="getUserInfo"></slot>
		</div>
		<page-footer></page-footer>
		<slot name="footer"></slot>
	</div>
</template>

<script>
import PageFooter from './PageFooter'
import PageHeader from './PageHeader'
export default {
	name: "Page",
	components: {PageHeader, PageFooter},
	props: {
		absBgImg: String,
		height: {
			type: [String, Number],
			default: ''
		},
		code:{
			type:String,
			default:''
		},
		titlePos:{
			type:String,
			default:'left'
		},
		search: {
			type: Boolean,
			default: false
		},
		back: {
			type: Boolean,
			default: false
		},
		absTitle: {
			type: String,
			default: ''
		},
		absSubTitle: {
			type: String,
			default: ''
		},
		absDes: {
			type: String,
			default: ''
		},
		miniTitle: {
			type: [String, Object],
			default: ''
		},
		bread: {
			type: Array,
			default: () => {
				return []
			}
		},
		titleClass: {
			type: String,
			default: ''
		},
		subTitleClass: {
			type: String,
			default: ''
		},
		desClass: {
			type: String,
			default: ''
		},
		titleStyle: {
			type: String,
			default: ''
		},
		subTitleStyle: {
			type: String,
			default: ''
		},
		desStyle: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: 'light'
		},
		searchPath: {
			type: String,
			default: '',
		},
		searchSlugs: {
			type: Array,
			default() {
				return []
			}
		},
		stopSearch: {
			type: Boolean,
			default: false
		},
		showYear: {
			type: Boolean,
			default: false
		},
		showPolicy: {
			type: Boolean,
			default: false
		},
		showMmType: {
			type: Boolean,
			default: false,
		},
		toPath: {
			type: String,
			default: '',
		},
		searchTimeKey: {
			type: String,
			default: 'create_time'
		}
	},
	data() {
		return {
			sideMenu: false,
			scrollTop: 0,
			isLogin: false,
			company:null,
			vip:null
		}
	},
	mounted() {
		// this.handleScroll();
		// window.addEventListener('scroll', this.handleScroll);
		// this.isLogin = !!this.$user.getToken();
		// if(this.isLogin){
		// 	this.getUserInfo();
		// }
	},
	methods: {
		getUserInfo(){
			let _this = this;
			this.$refs.header.getUserInfo();
			// this.$user.getUserInfo(()=>{
			// 	_this.vip = _this.$user.getVip();
			// 	_this.company = _this.$user.getCompany();
			// })
		},
		showSideMenu() {
			this.sideMenu = true;
		},
		handleScroll(e) {
			this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
		},
		logout() {
			this.$user.logout();
			this.$message.success('你已退出登录');
			location.reload();
			// this.$router.push({path:'/login'});
		},
		onSearch(res) {
			this.$emit('onSearch', res);
		}
	},
	computed: {
		op() {
			let _op = this.type === 'light' ? this.scrollTop / 50 : 1;

			return _op >= 1 ? {op: 1, class: 'nav-dark', logo: 'logo_dark.png', color: '#333'} : {op: _op, class: '', logo: 'logo.png', color: '#fff'};
		},
	}
}
</script>
<style scoped>

</style>
