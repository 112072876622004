<template>
    <lee-page bg-img="/images/bg_head_9.jpg" :type="$isMobile ? 'dark' : 'light'" :title-class="$isMobile ? '' : 'margin-top-100'" :height="$isMobile ? '50' : '280'" :back="$isMobile" :title="$isMobile ? '' : '数据平台行业数据分析报告——演示'" :bread="['行业数据','演示']">
        <div :class="$isMobile ? 'padding-left-right bg-light' : 'bg-img-2  padding-top-50'" :style="{backgroundColor:$isMobile ? '' : '#f8f9fa'}">
            <div :class="$isMobile ? '' : 'wrap1100'">
                <lee-year-tab :list="yearList" :class="$isMobile ? 'margin-top' : ''"></lee-year-tab>
                <div :class="$isMobile ? '' : 'box-shadow padding-30 bg-light margin-top-30'">
                    <div class="green-line">
                        <div class="cl f34">
                            <lee-img-icon url="/images/icon/icon_31.png" size="30" color="#7ED321" class="z margin-right" v-if="!$isMobile"></lee-img-icon>
                            <div class="z" :class="$isMobile ? 'f18 margin-top dark-2 block' : 'f22'">数据平台行业数据分析报告——进出口</div>
                        </div>
                        <el-row :gutter="20" class="dark-3 margin-top" :class="$isMobile ? 'f12' : 'f14'">
                            <el-col :md="5" :xs="12">发布机构：湖北省政府</el-col>
                            <el-col :md="5" :xs="12">发布时间：2020-03-26</el-col>
                            <el-col :md="5" :xs="12">文号：国药监注[2019]33号</el-col>
                        </el-row>
                    </div>
                    <div class="f16 dark-2" v-if="data">
                        {{yearList[yearList.length - 1].value}}年 - {{nowYear}}年进出口总体情况如下：
                    </div>
                    <div class="text-center">
                        <lee-bar-echarts
                            ref="hebing"
                            :grid-bottom="$isMobile ? 50 : 30"
                            :height="$isMobile ? '250px' : '350px'"
                            show-legend
                        ></lee-bar-echarts>
                    </div>
                    <div class="f16 dark-2">

                    </div>
                </div>
            </div>
        </div>
    </lee-page>
</template>

<script>
export default {
    name: "List",
    data(){
        return{
            yearList:[],
            nowYear:'',

            startYear:'',
            endYear:'',

            data:null,
            yearRange:[]
        }
    },
    created() {
        this.nowYear = this.$route.query.year ? this.$route.query.year : '';
    },
    mounted() {
        this.getYears();
    },
    methods:{
        getYears() {
            let _this = this;

            this.$http.post({
                url: '/HydataInout/getYears',
                data:null,
                success(res) {
                    for (let i = 0;i < res.length;i++){
                        _this.yearList.push({label:res[i]+'年',value:res[i]});
                    }

                    _this.endYear = res[res.length - 1];

                    _this.yearRange = [_this.endYear,res[0]];

                    _this.nowYear = _this.nowYear ? _this.nowYear : res[0];

                    _this.getTotal1(_this.nowYear);
                }
            });
        },

        getTotal1(year){
            //enterprise/statistics
            let _this = this;
            this.$http.post({
                url:'/HydataInout/getData',
                data:{year:year,yearlist:[this.nowYear,this.endYear]},
                success(res){
                    _this.data = res;

                    let _bardata = {bar:[],line:[],xaxis:[]};
                    let _hebing = {bar1:[],bar2:[],line1:[],line2:[],xaxis:[]};

                    for(let i=0;i < res.inout.list.length;i++) {
                        _bardata.bar.push(res.inout.list[i].total);
                        _bardata.line.push(res.inout.list[i].rate);
                        _bardata.xaxis.push(res.inout.list[i].year.toString());
                        _hebing.xaxis.push(res.inout.list[i].year.toString());
                    }

                    if(_this.$refs.bar1) {
                        _this.$refs.bar1.initData(
                            [
                                {name: '进出口总额', type: 'bar', color: 'green', data: _bardata.bar},
                                {name: '增长率', type: 'line', color: 'purple', data: _bardata.line},
                            ],
                            _bardata.xaxis
                        );
                    }

                    let _bardata2 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.out.list.length;i++) {
                        _bardata2.bar.push(res.out.list[i].total);
                        _bardata2.xaxis.push(res.out.list[i].year.toString());

                        _hebing.bar2.push(res.out.list[i].total);
                        _hebing.line2.push(res.out.list[i].rate);
                    }

                    if(_this.$refs.bar2) {
                        _this.$refs.bar2.initData(
                            [
                                {name: '出口总额', type: 'bar', color: 'green', data: _bardata2.bar},
                            ],
                            _bardata2.xaxis
                        );
                    }

                    let _bardata3 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.out.now.list.length;i++) {
                        _bardata3.bar.push(res.out.now.list[i].amount);
                        _bardata3.xaxis.push(res.out.now.list[i].title);
                    }

                    if(_this.$refs.bar3) {
                        _this.$refs.bar3.initData(
                            [
                                {name: '农产品出口总额', type: 'bar', color: 'most', data: _bardata3.bar},
                            ],
                            _bardata3.xaxis
                        );
                    }

                    let _bardata4 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.in.list.length;i++) {
                        _bardata4.bar.push(res.in.list[i].total);
                        _bardata4.xaxis.push(res.in.list[i].year.toString());

                        _hebing.bar1.push(res.in.list[i].total);
                        _hebing.line1.push(res.in.list[i].rate);
                    }

                    if(_this.$refs.bar4) {
                        _this.$refs.bar4.initData(
                            [
                                {name: '进口总额', type: 'bar', color: 'green', data: _bardata4.bar},
                            ],
                            _bardata4.xaxis
                        );
                    }

                    let _bardata5 = {bar:[],xaxis:[]};

                    for(let i=0;i < res.in.now.list.length;i++) {
                        _bardata5.bar.push(res.in.now.list[i].amount);
                        _bardata5.xaxis.push(res.in.now.list[i].title);
                    }

                    if(_this.$refs.bar5) {
                        _this.$refs.bar5.initData(
                            [
                                {name: '农产品进口总额', type: 'bar', color: 'most', data: _bardata5.bar},
                            ],
                            _bardata5.xaxis
                        );
                    }

                    if(_this.$refs.hebing) {
                        _this.$refs.hebing.initData(
                            [
                                {name: '进口总额', type: 'bar', color: 'green', data: _hebing.bar1},
                                {name: '出口总额', type: 'bar', color: 'blue', data: _hebing.bar2},
                                {name: '进口额增长率', type: 'line', color: 'purple', data: _hebing.line1},
                                {name: '出口增长率', type: 'line', color: 'red', data: _hebing.line2},
                            ],
                            _hebing.xaxis
                        );
                    }
                }
            })
        },
    }
}
</script>
