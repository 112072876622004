<template>
	<div class="flex flex-column flex-ai" style="height: 100%;">
		<div class="margin-top f14 text-center margin-top-50">当前打开的网页不适用，请复制网址<u class="green f-b">{{ $config.webUrl }}</u>后到电脑端访问</div>
		<el-button @click="toCopy" type="primary" size="small" class="margin-top">点击复制网址</el-button>
		<div class="margin-top f14 text-center">或保存下方二维码后，通过微信<u class="green f-b">扫一扫</u>访问小程序</div>
		<img class="margin-top-30" :src="weixin_mini_qrcode.indexOf('http') >= 0 ? weixin_mini_qrcode : $config.assetsUrl+weixin_mini_qrcode" style="width: 100%;"/>
		<div class="margin-top f14">长按上方<u class="green f-b">二维码</u>保存图片，在微信中<u class="green f-b">扫一扫</u>进入小程序</div>
	</div>
</template>

<script>
export default {
	name: "MiniQrCode",
	data() {
		return {
			weixin_mini_qrcode: ''
		}
	},
	mounted() {
		this.getSetting();
	},
	methods: {
		toCopy(){
			let _this = this;
			this.$copyText(this.$config.webUrl).then(function (e) {
				_this.$message.success('复制成功');
			}, function (e) {

			})
		},
		getSetting() {
			let _this = this;

			this.$http.post({
				url: '/SystemSetting/getSystem',
				data: {skey: 'weixin_mini_qrcode'},
				success(res) {
					console.log(res);
					_this.weixin_mini_qrcode = res.weixin_mini_qrcode;
				}
			});
		},
	}
}
</script>
